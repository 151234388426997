import styled, { css } from "styled-components"

export const PageWrapper = styled.div`
  min-height: 100vh;
  position: relative;
  overflow: scroll;
  font-family: 'Roboto', sans-serif;
  font-family: 'Roboto Condensed', sans-serif;

  ${props =>  css({
      backgroundColor: props.background,
      paddingBottom: props.noPadding ? '0px' : '130px'
    })
  }
`

export const Title = styled.div`
  padding: 16px;
  font-size: 24px;

  ${props => css({
      color: props.color || 'white',
    })
  }
`

export const PageHeader = styled.h1`
  padding: 16px;
  font-size: 36px;
  color: #333;
  font-weight: 300;
  text-align: center;
  margin: 0;
`

export const PageSubheader = styled.h2`
  padding: 16px;
  font-size: 24px;
  color: #333;
  font-weight: 300;
  text-align: center;
  margin: 0;
`

export const FinalizarCompra = styled.div`
  position: fixed;
  bottom: -19px;
  width: 180px;
  left: calc(50% - 90px);
  z-index: 4;
`