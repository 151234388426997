import { useEffect, useState } from "react"
import { useRecoilState } from "recoil"
import { Helmet } from "react-helmet-async"
import styled, {css} from "styled-components"
import { BsWhatsapp, BsInstagram } from 'react-icons/bs'
import Menu from "../components/Menu"
import CartMenu from '../components/CartMenu'
import { CenterMenu, FooterWrapper, MenuWrapper, MenuWrapperDesktop } from '../components/HomeStyles'
import { PageWrapper } from '../components/DefaultStyles'
import { vendedorState } from "../atoms/vendedorAtom"
import LogoMenu from "../components/LogoMenu"
import { BrowserView, MobileView } from "react-device-detect"
import MenuDesktop from "../components/MenuDesktop"
import Footer from "../components/Footer"

const BoxContent = styled.div`
  padding: 12px;
  font-weight: 300;
  color: #333;
  font-size: 16px;
  max-width: 1200px;
  margin: auto;
`

const Title = styled.h2`
  font-size: 46px;
  padding: 16px 24px 0 24px;
  margin-bottom: 0;
  font-family: 'Bebas Neue';
  font-weight: 300;
  color: rgba(12,145,156,1);
`

const BoxInfo = styled.div`
  padding: 4px 24px;
  margin-bottom: 4px;
  display: flex;
  align-items: center;

  svg{
    margin-left: 4px;
  }

  a {
    margin-left: 8px;
    display: flex;
    align-items: center;
    color: #333;
  }
`

const Content = styled.p`
  padding: 8px 24px;

  ${props => props.center && css({
    textAlign: "center"
  })}
`

const TitleSmall = styled(Title)`
  font-size: 30px;
`

const ListWrapper = styled.ul`
  list-style: square;
  padding-left: 20px;
`

export default function Contatos() {
  const [vendedor] = useRecoilState(vendedorState)
  const [telefone, setTelefone] = useState('')

  useEffect(() => {
    if(vendedor.telefone) {
      setTelefone(vendedor.telefone.replace("-", "").replace("(", "").replace(")", "").replace(" ", ""))
    }
  }, [vendedor])

  return (
    <PageWrapper background={'white'}>
      <Helmet>
        <title>Contatos</title>
      </Helmet>

      <MobileView>
        <MenuWrapper color={"rgba(12,145,156, 1)"}>
          <Menu background={'#999'} />
          <LogoMenu />
          <CartMenu />
        </MenuWrapper>
      </MobileView>

      <BrowserView>
        <CenterMenu>
          <MenuWrapperDesktop color={"rgba(12,145,156, 1)"}>
            <LogoMenu />
            <MenuDesktop />
            <CartMenu />
          </MenuWrapperDesktop>
        </CenterMenu>
      </BrowserView>

      <BoxContent>
        <Title>Contatos</Title>

        <BoxInfo>
          <BsWhatsapp /> <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=55${telefone}`}> {vendedor.telefone} </a> <br/>
        </BoxInfo>
        <BoxInfo>
          <BsInstagram /> <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/agenda.namaste/`}> agenda.namaste </a>
        </BoxInfo>

        <TitleSmall>Estamos em Porto Alegre, Salvador, Belo Horizonte, Rio de Janeiro, Brasilia e São Paulo</TitleSmall>

        <BoxInfo>
          <BsWhatsapp /> <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=5551996128824`}> 51 99612-8824 </a> <br/>
        </BoxInfo>
        
        <TitleSmall>Politica de devolução</TitleSmall>
        <Content>
          Se quiser trocar ou devolver seu pedido – estamos aqui para ajudar! O Código de Defesa do Consumidor define que trocas e devoluções podem ser realizadas em até 7 dias em caso de arrependimento. Após esse período, são gratuitas pelo prazo de 30 dias após a data do seu pedido. Você pode trocar seu produto por crédito na loja da Agenda Namastê, ou receber reembolso pela mesma forma de pagamento.
        </Content>

        <Content>
          Observe, no entanto, as exceções da nossa política:
          * Itens com desconto são de venda final e não passíveis de trocas ou devoluções;
          * Itens devolvidos devem conter a etiqueta e ser enviados na embalagem original;
          * Itens devolvidos não devem ter sinais visíveis de desgaste ou uso.
        </Content>

        <Content>
          Para começar uma troca ou devolução, siga os seguintes passos:
          <ListWrapper>
            <li>Entre em contato com nosso atendimento via WhatsApp - 51981145137 ou via Instagram @agenda.namaste;</li>
            <li>Informe o nome completo que consta no pedido, número do pedido, telefone e e-mail de contato e o motivo para troca ou devolução;</li>
            <li>Imprima a etiqueta de transporte pré-pago dos correios que você receberá;</li>
            <li>Nos devolva todos os artigos usando a etiqueta fornecida em qualquer agência dos correios.</li>
          </ListWrapper>
        </Content>

        <Content>
          Informações adicionais:
          <ListWrapper>
            <li>O reembolso só será efetuado após receber o item de devolução, após avaliado qualquer avaria no produto.</li>
            <li>Em caso de extravio de agenda, não recebimento, entrar em contato com nossos canais. Nesse caso, o reenvio acontecerá SOMENTE após expirado o prazo dado pelos correios para entrega do produto. Ainda, será cobrado novo frete se o cliente houver informado na primeira compra um endereço errado. </li>
            <li>Para qualquer outra dúvida, entrar em contato com nossos canais.</li>
          </ListWrapper>
        </Content>
      </BoxContent>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </PageWrapper>
  )
}
