import { useEffect, useState } from 'react'
import { useCart } from 'react-use-cart'
import MaskedInput from 'react-text-mask'
import { useRecoilState, useRecoilValue } from 'recoil'
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri'
import { Resumo } from './Resumo'
import { BuyButtonStyled } from '../HomeStyles'
import styled, { css } from "styled-components"
import { brlCurrency } from '../../helpers/agendas'
import * as api from '../../helpers/api'
import { freteState, valorFreteState, validEntregaFieldState } from '../../atoms/freteAtom'
import { loadingAppState } from '../../atoms/globalAtom'
import { CheckboxWrapper, OpcaoCheckbox } from './CarrinhoStyles'

const FieldSet = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  justify-content: end;
`

const Label = styled.label`
  margin-right: 12px;
  font-size: 18px;
  width: 30%;
`

const LabelEnd = styled(Label)`
  width: 18%
  margin-bottom: 12px;
`

const Input = styled.input`
  padding: 8px;
  font-size: 18px;
  width: 70%;
  margin-bottom: 12px;

  ${props => props.error && css({
    border: "solid 1px gray",
    borderRadius: "3px",
    borderBottom: "solid 2px #E22",
    color: "#E22",
    padding: "8px 8px 8px 9px",
  })}
`

const InputMask = styled(MaskedInput)`
  padding: 8px;
  font-size: 18px;
  width: 200px;

  ${props => props.$error && css({
    border: "solid 1px gray",
    borderRadius: "3px",
    borderBottom: "solid 2px #E22",
    color: "#E22",
    padding: "8px 8px 8px 9px",
  })}
`

const WrapperFreteOpcoes = styled.div`
  font-size: 18px;
`

const FreteButton = styled(BuyButtonStyled)`
  width: 50%;
  margin-left: auto;
  margin-top: 12px;
  margin-bottom: 8px;
;`


const CartTotal = styled.div`
  padding: 20px;
  text-align: right;
  font-size: 24px;
`

const InvalidCep = styled.div`
  color: rgba(250,0,0,0.8);
  font-size: 20px;
`

const InformativoDeEntrega = styled.div`
  padding-top: 12px;
  margin-bottom: 8px;
  font-size: 16px;
  font-weight: bold;
`

export default function Frete({stepId, activeStep}) {
  const initialFrete = { selectFrete: null, opcoesDeFrete: [] }
  const showResume = stepId < activeStep
  const hideEdit = stepId > activeStep
  
  const { totalItems } = useCart()
  const [cep, setCep] = useState('')
  const [cepInvalid, setCepInvalid] = useState(false)
  const [frete, setFrete] = useState(initialFrete)
  const [, setLoading] = useRecoilState(loadingAppState)
  const [cepError, setCepError] = useState(false)
  const [endereco, setEndereco] = useRecoilState(freteState)
  const [, setValorFrete] = useRecoilState(valorFreteState)
  const validEndereco = useRecoilValue(validEntregaFieldState)
  const maskCep = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]

  useEffect(() => {
    setValorFrete({id: null, valor: 0})
    setCepInvalid(false)
    setFrete(initialFrete)
  }, [totalItems])

  function isInvalidCep(inputCep) {
    return inputCep.length !== 9
  }

  function getOpcoesFrete() {
    setValorFrete({id: null, valor: 0})
    setCepInvalid(false)
    setFrete(initialFrete)
    setLoading(true)
    setCepError(false)

    if (isInvalidCep(cep)) {
      setFrete({
        ...frete,
        selectFrete: null,
        opcoesDeFrete: []
      })
      setCepInvalid(true)
      setLoading(false)
      return
    }
    
    api.opcoesDeFrete(cep, totalItems).then(data => {
        setLoading(false)
        if(data.status === "error") {
          setCepError(true)
        }
        const opcoesDeFrete = data.data[0].valores.map(opcao => {
          return {
            ...opcao,
            valor: typeof opcao.valor === 'number' ? opcao.valor : parseFloat(parseFloat(opcao.valor.replace(',', '.')).toPrecision(2))
          }
        })
        opcoesDeFrete.sort((prev, curr) => parseInt(prev.valor) - parseInt(curr.valor))
        setFrete({
          selectFrete: null,
          opcoesDeFrete: opcoesDeFrete
        })
      })
      .catch((e) => {
        setLoading(false)
      })

    api.consultaCep(cep).then(data => {
      if(data.status !== "error") {
        setEndereco({
          ...endereco,
          cep: cep,
          bairro: data.data[0].bairro,
          cidade: data.data[0].cidade,
          endereco: data.data[0].end,
          estado: data.data[0].uf
        })
      }
    })
  }

  function selectFrete(id) {
    setFrete({
      ...frete,
      selectFrete: id
    })

    const valorFrete = id === null ? 0 : frete.opcoesDeFrete.find(f => f.id === id).valor
    setValorFrete({id: id, valor: valorFrete})
  }

  function selectedFreteValor() {
    return frete.selectFrete === null ? 0 :
    frete.opcoesDeFrete.find(f => f.id === frete.selectFrete).valor
  }

  if(hideEdit) {
    return null
  }

  if(showResume) {
    return <Resumo>
      Frete: {brlCurrency(selectedFreteValor())}
    </Resumo>
  }

  return (
    <CartTotal>
      <FieldSet>
        <Label htmlFor="cep">Meu Cep</Label>
        <InputMask
          id="cep"
          type="text"
          $error={cepInvalid}
          mask={maskCep}
          guide={true}
          onChange={e => {
            setCep(e.target.value)
            setEndereco({...endereco, cep: e.target.value})
            setCepInvalid(false)
          }}
        />
      </FieldSet>

      <FreteButton
        color={"#b7514b"}
        onClick={() => getOpcoesFrete()}
      >
        Buscar
      </FreteButton>

      {cepError && <InvalidCep>Houve algum erro para achar o ceu cep nos correios</InvalidCep>}

      {frete.opcoesDeFrete.length > 0 && <WrapperFreteOpcoes>
        <FieldSet>
          <LabelEnd htmlFor="endereco">Endereço</LabelEnd>
          <Input
            id="endereco" type="text"
            value={endereco.endereco}
            error={!validEndereco.endereco}
            onChange={e => setEndereco({...endereco, endereco: e.target.value})}
          />
        </FieldSet>
        <FieldSet>
          <LabelEnd htmlFor="bairro">Bairro</LabelEnd>
          <Input
            id="bairro" type="text"
            value={endereco.bairro}
            error={!validEndereco.bairro}
            onChange={e => setEndereco({...endereco, bairro: e.target.value})}
          />
        </FieldSet>
        <FieldSet>
          <LabelEnd htmlFor="cidade">Cidade</LabelEnd>
          <Input
            id="cidade" type="text"
            value={endereco.cidade}
            error={!validEndereco.cidade}
            onChange={e => setEndereco({...endereco, cidade: e.target.value})}
          />
        </FieldSet>
        <FieldSet>
          <LabelEnd htmlFor="estado">Estado (UF)</LabelEnd>
          <Input
            id="estado"
            type="text"
            length="2"
            value={endereco.estado}
            error={!validEndereco.estado}
            onChange={e => {
              if(e.target.value.length > 2) {
                return
              }
              setEndereco({...endereco, estado: e.target.value})}
            }
          />
        </FieldSet>
        <FieldSet>
          <LabelEnd htmlFor="numero">Número</LabelEnd>
          <Input
            id="numero" type="text"
            value={endereco.numero}
            error={!validEndereco.numero}
            onChange={e => setEndereco({...endereco, numero: e.target.value})}
          />
        </FieldSet>
        <FieldSet>
          <LabelEnd htmlFor="numero">Complemento</LabelEnd>
          <Input
            id="numero" type="text"
            value={endereco.complemento}
            onChange={e => setEndereco({...endereco, complemento: e.target.value})}
          />
        </FieldSet>

        {frete.opcoesDeFrete.map((f) => {
          return (
            <OpcaoCheckbox
              active={frete.selectFrete === f.id}
              onClick={() => {selectFrete(f.id)}} key={f.id}
            >
              <CheckboxWrapper>
                {(!frete.selectFrete || frete.selectFrete !== f.id) && <RiCheckboxBlankCircleLine color={'#333'}/>}
                {frete.selectFrete === f.id && <RiCheckboxCircleFill color={"#b7514b"} />}
              </CheckboxWrapper>
              <div>{f.descricao} {brlCurrency(f.valor)} - {f.prazo} dias</div>
            </OpcaoCheckbox>
          )
        })}

        {/* <InformativoDeEntrega>
          Compras efetuadas entre os dias 17 e 26 de Fevereiro serão enviadas somente no dia 27 de Fevereiro.
        </InformativoDeEntrega> */}
      </WrapperFreteOpcoes>}

      <div>Frete: {brlCurrency(selectedFreteValor())}</div>
    </CartTotal>
  )
}
