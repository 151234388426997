import { atom } from "recoil"

export const loadingAppState = atom({
  key: 'loadingAppState',
  default: false,
})

export const promoActiveState = atom({
  key: 'promoActiveState',
  default: false,
})