import styled from "styled-components"
import ReactLoading from 'react-loading'

const Modal = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
  background: rgba(0,0,0,0.2);
  position: fixed;
  top: 0;
  z-index: 10;
  justify-content: center;
  align-items: center;
`

export default function Loading() {
  return (
    <Modal>
      <ReactLoading type={'spin'} color={'rgba(29, 31, 167, 1)'} height={80} width={80} />
    </Modal>
  )
}