import { atom, selector } from "recoil"
import { brlCurrency } from "../helpers/agendas"

export const parcelasState = atom({
  key: 'parcelasState',
  default: [
    {
      "parcelas": 0,
      "valor": 0,
      "total": 0
    }
  ]
})

export const loadingParcelasState = atom({
  key: 'loadingParcelasState',
  default: true
})

export const parcelasQuery = selector({
  key: 'parcelasQuery',
  get: ({get}) => {
    const parcelas = get(parcelasState)

    return parcelas.map(parcela => {
      return {
        value: parcela.parcelas,
        label: `${parcela.parcelas}x ${brlCurrency(parcela.valor)} - Total ${brlCurrency(parcela.total)}`,
      }
    })
  }
})