import { atom, selector } from "recoil"
import BrV from 'br-validations'
import { isPossiblePhoneNumber } from 'react-phone-number-input'

const validName = nome => nome !== null && nome.length > 1
const validTelefone = tel => tel !== null && tel !== undefined && isPossiblePhoneNumber(tel)
const validEmail = email => email !== null && email.includes('@') && email.includes('.')
const validCpf = cpf => cpf !== null && BrV.cpf.validate(cpf)

export const clienteState = atom({
  key: 'clienteState',
  default: {
    nome: null,
    sobrenome: null,
    telefone: null,
    email: null,
    cpf: null,
  }
})

export const validClienteFieldQuery = selector({
  key: 'validClienteFieldQuery',
  get: ({get}) => {
    const cliente = get(clienteState)

    return {
      nome: validName(cliente.nome),
      sobrenome: validName(cliente.sobrenome),
      telefone: validTelefone(cliente.telefone),
      email: validEmail(cliente.email),
      cpf: validCpf(cliente.cpf),
    }
  }
})

export const validClienteQuery = selector({
  key: 'validClienteQuery',
  get: ({get}) => {
    const { nome, sobrenome, telefone, email, cpf } = get(clienteState)
    return validName(nome) &&
      validName(sobrenome) &&
      validTelefone(telefone) &&
      validEmail(email) &&
      validCpf(cpf)
  }
})
