import styled from "styled-components"

const StyledResumo = styled.div`
  padding: 12px;
  background-color: seagreen;
  color: white;
  border-bottom: solid 1px white;
`

export function Resumo(props) {
  return <StyledResumo>
    {props.children}
  </StyledResumo>
}
