import { gsap, Circ, Bounce } from "gsap";

export function cartIconPop() {
  gsap.to(
    `#cart-menu-icon div`,
    {
      duration: 0.15,
      ease: Circ.easeOut,
      marginTop: "-12px"
    }
  )

  gsap.to(
    `#cart-menu-icon div`,
    {
      delay: 0.15,
      duration: 0.5,
      ease: Bounce.easeOut,
      marginTop: "0px"
    }
  )
}