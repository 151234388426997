import { Link } from "react-router-dom";
import { AgendaCapaDesk, AgendaCapaWrapper } from "../HomeStyles";

export function AgendaHomeComponentDesk({agendaActual, agenda, cadernoIndex}) {
  let caderno = () => true

  if (agenda.isCaderno) {
    caderno = cartInfoIndex => cartInfoIndex === cadernoIndex
  }

  return agenda.cartInfo.map((cartInfo, index) => (
    <Link to={`/detalhes/${cartInfo.slug}`} key={index}>
      <AgendaCapaWrapper id={`agenda-${cartInfo.id}`} active={agendaActual === agenda.order && caderno(index)}>
        <AgendaCapaDesk
          id={`agenda-${agenda.order}`}
          esgotado={cartInfo.esgotado}
          src={cartInfo.imageDesk}
          alt={cartInfo.alt}
          title={cartInfo.nome}
          active={agendaActual === agenda.order}
        />
      </AgendaCapaWrapper>
    </Link>
  ))
}