import { selector } from "recoil"
import { serializeTelefone } from "../helpers/serializer"
import { cartaoDeCreditoState } from "./cartaoDeCreditoAtom"
import { clienteState } from "./clienteAtom"
import { freteState, valorFreteState } from "./freteAtom"

export const finalizarCompraQuery = selector({
  key: 'finalizarCompraQuery',
  get: ({get}) => {
    const card = get(cartaoDeCreditoState)
    const endereco = get(freteState)
    const frete = get(valorFreteState)
    const cliente = get(clienteState)

    return {
      cliente: {
        ...cliente,
        telefone: serializeTelefone(cliente.telefone)
      },
      frete,
      endereco,
      parcelas: card.parcelas.parcelas
    }
  }
})
