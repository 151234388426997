import { gsap, Sine, Power0, Expo } from "gsap";

const transitionTime = 0.45

export function espiralToBrochura(id, onComplete) {
  gsap.to(
    `#agenda-${id}`,
    {
      duration: 0.25,
      ease: Sine.easeOut,
      left: '-400px',
      opacity: 0,
      onComplete: onComplete,
      clearProps: 'left,opacity',
    }
  )

  gsap.from(
    `#agenda-brochura-${id}`,
    {
      delay: 0.4,
      duration: 0.25,
      ease: Sine.easeOut,
      left: '400px',
      opacity: 0,
      clearProps: 'left,opacity',
    }
  )

  gsap.to(
    '#agendaPreco',
    {
      duration: 0.25,
      ease: Sine.easeOut,
      textAlign: 'right',
    }
  )
}

export function swipeLeft(agenda, cadernoIndex, setCadernoIndex) {
  if(agenda.isCaderno) {
    const newCadernoIndex = agenda.cartInfo.length === (cadernoIndex + 1) ? 0 : cadernoIndex + 1
    
    const id = agenda.cartInfo[newCadernoIndex].id
    const oldId = agenda.cartInfo[cadernoIndex].id
  
    gsap.to(
      `#agenda-${oldId}`,
      {
        duration: 0.25,
        ease: Sine.easeOut,
        left: '-400px',
        opacity: 0,
        onComplete: () => setCadernoIndex(newCadernoIndex),
        clearProps: 'left,opacity',
      }
    )
    
    gsap.from(
      `#agenda-${id}`,
      {
        delay: 0.4,
        duration: 0.25,
        ease: Sine.easeOut,
        left: '400px',
        opacity: 0,
        clearProps: 'left,opacity',
      }
    )

    gsap.to(
      '#agendaPreco',
      {
        duration: 0.25,
        ease: Sine.easeOut,
        textAlign: 'left',
      }
    )
  }
}

export function swipeRight(agenda, cadernoIndex, setCadernoIndex) {
  if(agenda.isCaderno) {
    const newCadernoIndex = (cadernoIndex - 1) < 0 ? (agenda.cartInfo.length - 1) : cadernoIndex - 1
    

    const id = agenda.cartInfo[newCadernoIndex].id
    const oldId = agenda.cartInfo[cadernoIndex].id
  
    gsap.to(
      `#agenda-${oldId}`,
      {
        duration: 0.25,
        ease: Sine.easeOut,
        right: '-400px',
        opacity: 0,
        onComplete: () => setCadernoIndex(newCadernoIndex),
        clearProps: 'right,opacity',
      }
    )

    gsap.from(
      `#agenda-${id}`,
      {
        delay: 0.4,
        duration: 0.25,
        ease: Sine.easeOut,
        right: '400px',
        opacity: 0,
        clearProps: 'right,opacity',
      }
    )

    gsap.to(
      '#agendaPreco',
      {
        duration: 0.25,
        ease: Sine.easeOut,
        textAlign: 'right',
      }
    )
  }
}

export function brochuraToEspiralRight(id, onComplete) {
  gsap.to(
    `#agenda-brochura-${id}`,
    {
      duration: 0.25,
      ease: Sine.easeOut,
      right: '-400px',
      opacity: 0,
      onComplete: onComplete,
      clearProps: 'right,opacity',
    }
  )
  
  gsap.from(
    `#agenda-${id}`,
    {
      delay: 0.4,
      duration: 0.25,
      ease: Sine.easeOut,
      right: '400px',
      opacity: 0,
      clearProps: 'right,opacity',
    }
  )

  gsap.to(
    '#agendaPreco',
    {
      duration: 0.25,
      ease: Sine.easeOut,
      textAlign: 'right',
    }
  )
}

export function initializeAgenda() {
  gsap.to('#bottom-background',
    {
      duration: 320,
      repeat: -1,
      backgroundPosition: 'center -4219px',
      ease: Power0.easeNone 
    }
  )
}

export function changeAgendaUp(id, lastId, onComplete, agendas) {
  const agendaLast = agendas.find(agenda => agenda.order === lastId)
  const currentAgenda = agendas.find(agenda => agenda.order === id)

  gsap.to(
    `#agenda-${agendaLast.cartInfo[0].id}`,
    {
      duration: transitionTime,
      ease: Sine.easeOut,
      top: '-800px',
      clearProps: 'top',
      onComplete: () => onComplete(id),
    }
  )

  gsap.from(
    `#agenda-${currentAgenda.cartInfo[0].id}`,
    {
      delay: transitionTime,
      duration: transitionTime,
      ease: Sine.easeOut,
      top: '800px',
      clearProps: 'top',
    }
  )


  if(agendaLast.isCaderno && !currentAgenda.isCaderno) {
    hideArrow()
  }
  
  if(!agendaLast.isCaderno && currentAgenda.isCaderno) {
    showArrow()
  }
}

export function changeAgendaDown(id, lastId, setAgendaActual, agendas) {
  const agendaLast = agendas.find(agenda => agenda.order === lastId)
  const currentAgenda = agendas.find(agenda => agenda.order === id)

  gsap.to(
    `#agenda-${agendaLast.cartInfo[0].id}`,
    {
      duration: transitionTime,
      ease: Sine.easeOut,
      bottom: '-800px',
      clearProps: 'bottom',
      onComplete: () => { setAgendaActual(id) },
    }
  )

  gsap.from(
    `#agenda-${currentAgenda.cartInfo[0].id}`,
    {
      delay: transitionTime,
      duration: transitionTime,
      ease: Sine.easeOut,
      bottom: '800px',
      clearProps: 'bottom',
    }
  )

  if(agendaLast.isCaderno && !currentAgenda.isCaderno) {
    hideArrow()
  }
  
  if(!agendaLast.isCaderno && currentAgenda.isCaderno) {
    showArrow()
  }
}

export function showArrow() {
  gsap.to(
    `#toLeft`,
    {
      delay: transitionTime,
      duration: transitionTime,
      ease: Sine.easeOut,
      opacity: 0.8,
    }
  )

  gsap.to(
    `#toRight`,
    {
      delay: transitionTime,
      duration: transitionTime,
      ease: Sine.easeOut,
      opacity: 0.8,
    }
  )
}

export function hideArrow() {
  gsap.to(
    `#toLeft`,
    {
      delay: transitionTime,
      duration: transitionTime,
      ease: Sine.easeOut,
      opacity: 0,
    }
  )

  gsap.to(
    `#toRight`,
    {
      delay: transitionTime,
      duration: transitionTime,
      ease: Sine.easeOut,
      opacity: 0,
    }
  )
}

export function changeBackgroundDown(agenda) {
  changeBackground("prev", agenda.quero)
}

export function changeBackgroundUp(agenda) {
  changeBackground("next", agenda.quero)
}

function changeBackground(changeTo, buyColor) {
  changeBottomBackground()

  gsap.to(
    `#top-background-actual`,
    {
      duration: transitionTime,
      ease: Sine.easeOut,
      opacity: 0,
      clearProps: 'opacity',
    }
  )

  gsap.to(
    `#top-background-${changeTo}`,
    {
      duration: transitionTime,
      ease: Sine.easeOut,
      opacity: 1,
      clearProps: 'opacity',
    }
  )

  gsap.to("#buy-button", {
    duration: transitionTime,
    backgroundColor: buyColor
  })

  gsap.to("#go-to-cart", {
    duration: transitionTime,
    backgroundColor: buyColor
  })
}

function changeBottomBackground() {
  gsap.to('#bottom-background',
    {
      duration: transitionTime,
      opacity: 0,
      ease: Power0.easeNone 
    }
  )

  gsap.to('#bottom-background',
    {
      duration: transitionTime,
      backgroundPosition: 'center -1519px',
      ease: Expo.easeNone
    }
  )

  gsap.to('#bottom-background',
    {
      delay: transitionTime,
      duration: transitionTime,
      opacity: 1,
      ease: Power0.easeNone 
    }
  )
}

export function showGoToCart() {
  gsap.to("#go-to-cart",
    {
      duration: transitionTime / 2,
      ease: Sine.easeOut,
      opacity: '1',
    }
  )
}

export function hideGoToCart() {
  gsap.to("#go-to-cart",
    {
      duration: transitionTime / 2,
      ease: Sine.easeOut,
      opacity: '0',
    }
  )
}

export function showVerMais() {
  gsap.to("#touch-agenda-animation",
    {
      duration: transitionTime * 2,
      ease: Sine.easeOut,
      opacity: '1',
    }
  )

  gsap.to("#touch-agenda-animation",
    {
      delay: transitionTime * 3,
      duration: transitionTime * 2,
      ease: Sine.easeOut,
      opacity: '0',
    }
  )
}