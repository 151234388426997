import 'react-phone-number-input/style.css'

import styled, { css } from "styled-components"
import PhoneInput from 'react-phone-number-input'
import { useRecoilState, useRecoilValue } from "recoil"
import { clienteState, validClienteFieldQuery } from "../../atoms/clienteAtom"
import {
  FieldSet, Label, InputMask,
  Input, CartaoWrapper
} from './CarrinhoStyles'

const PhoneInputStyled = styled(PhoneInput)`
  border: solid 1px gray;
  borderRadius: 3px;
  font-size: 18px;
  width: 100%;

  ${props => props.$error && css({
    border: "solid 1px gray",
    borderRadius: "3px",
    borderBottom: "solid 2px #E22",
    color: "#E22",
  })}
`

export default function Dados({stepId, activeStep}) {
  const showResume = stepId < activeStep
  const hideEdit = stepId > activeStep

  const validClienteField = useRecoilValue(validClienteFieldQuery)
  const [cliente, setCliente] = useRecoilState(clienteState)
  
  const maskCpf = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/]

  if(hideEdit) {
    return null
  }

  if(showResume) {
    return null
  }

  return (
    <CartaoWrapper>
      <FieldSet>
        <Label htmlFor="nome">Nome</Label>
        <Input
          id="nome" type="text"
          value={cliente.nome === null ? '' : cliente.nome}
          error={!validClienteField.nome}
          onChange={e => setCliente({...cliente, nome: e.target.value})}
        />
      </FieldSet>
      <FieldSet>
        <Label htmlFor="nome">Sobrenome</Label>
        <Input
          id="sobrenome" type="text"
          value={cliente.sobrenome === null ? '' : cliente.sobrenome}
          error={!validClienteField.sobrenome}
          onChange={e => setCliente({...cliente, sobrenome: e.target.value})}
        />
      </FieldSet>
      <FieldSet>
        <Label htmlFor="telefone">Telefone</Label>
        <PhoneInputStyled
          defaultCountry="BR"
          $error={!validClienteField.telefone}
          value={cliente.telefone}
          onChange={e => setCliente({...cliente, telefone: e})}
        />
      </FieldSet>
      <FieldSet>
        <Label htmlFor="email">Email</Label>
        <Input
          id="email"
          type="text"
          value={cliente.email === null ? '' : cliente.email}
          error={!validClienteField.email}
          onChange={e => setCliente({...cliente, email: e.target.value})}
        />
      </FieldSet>
      <FieldSet>
        <Label htmlFor="cpf">CPF</Label>
        <InputMask
          id="cpf"
          mask={maskCpf}
          guide={false}
          value={cliente.cpf}
          $error={!validClienteField.cpf}
          onChange={e => setCliente({...cliente, cpf: e.target.value})}
        />
      </FieldSet>
    </CartaoWrapper>
  )
}
