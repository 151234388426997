import { createGlobalStyle, css } from 'styled-components';

export const GlobalStyle = createGlobalStyle(({ theme }) =>
  css({
    '*, *::before, *::after': {
      boxSizing: 'border-box',
    },
    body: {
      margin: 0,
      fontFamily: `${theme.fonts.body}`,
      fontWeight: 'regular',
      fontSize: 'base',
      lineHeight: '1.5',
      backgroundColor: 'white',
      WebkitTextSizeAdjust: '100%',
      WebkitFontSmoothing: 'antialiased',
      MozOsxFontSmoothing: 'grayscale',
      textShadow: '1px 1px 1px rgba(0, 0, 0, 0.004)',

      '.carousel .slide .legend': {
        background: 'rgb(0,0,0,0.5)',
        opacity: 1,
      }
    },
    a: {
      textDecoration: 'none',
    },
    '.PhoneInputInput, .PhoneInputCountry': {
      padding: '8px',
      border: 'none',
      outline: 'none',
      fontSize: '18px',
    }
  })
);