import styled, { css } from "styled-components"
import { useCart } from 'react-use-cart'
import Stepper from 'react-stepper-horizontal'
import { useRecoilState, useRecoilValue } from "recoil"
import { BrowserView, MobileView} from 'react-device-detect'
import { BuyButtonStyled, CenterMenu, MenuWrapper, MenuWrapperDesktop, PageContent } from '../components/HomeStyles'
import { PageWrapper } from '../components/DefaultStyles'
import Menu from '../components/Menu'
import CartMenu from '../components/CartMenu'
import Frete from '../components/carrinho/Frete'
import Items from '../components/carrinho/Items'
import Pagamento from '../components/carrinho/Pagamento'
import Dados from '../components/carrinho/Dados'
import CarrinhoVazio from '../components/carrinho/CarrinhoVazio'
import Finalizar from '../components/carrinho/Finalizar'
import Loading from '../components/Loading'
import { finalizarCompra, gerarTokenCartao, getParcelas } from "../helpers/api"
import { useHistory } from "react-router-dom"
import Swal from 'sweetalert2'
import { Helmet } from "react-helmet-async"

import { freteState, validEntregaState } from "../atoms/freteAtom"
import { cartaoDeCreditoState, enderecoDeCobrancaState, validEnderecoCobrancaQuery } from "../atoms/cartaoDeCreditoAtom"
import { validClienteQuery } from "../atoms/clienteAtom"
import { cartStepState } from "../atoms/cartStepAtom"
import { finalizarCompraQuery } from "../atoms/finalizarCompraAtom"
import { loadingAppState, promoActive, promoActiveState } from "../atoms/globalAtom"
import { serializePayload } from "../helpers/serializer"
import { pedidoFinalizadoState } from "../atoms/pedidoFinalizado"
import { useEffect } from "react"
import { sanitizeCart, scrollToTop } from "../helpers/agendas"
import { agendasState } from "../atoms/agendasAtom"
import { loadingParcelasState, parcelasState } from "../atoms/parcelasAtom"
import { vendedorState } from "../atoms/vendedorAtom"
import LogoMenu from "../components/LogoMenu"
import MenuDesktop from "../components/MenuDesktop"
import { validPagamentoState } from "../atoms/pagamentoAtom"
import { tipoCobrancaState } from "../atoms/tipoCobrancaAtom"
import { PAGAMENTO_BOLETO, PAGAMENTO_PIX } from "../helpers/constants"
import { aceitoTermosState } from "../atoms/aceitoTermosAtom"

const Wrapper = styled.div`
  padding: 0px 12px 12px 12px;

  ${props => props.browser && css({
    display: 'flex'
  })}
`

const StyledStepper = styled.div`
  margin-bottom: 24px;
  font-family: 'Bebas Neue';

  a {
    width: 100%;
    height: 100%;
    display: block;
    cursor: pointer;
  }
`

const WrapperBrowser = styled.div`
  width: 50%;
`

const DivBackground = styled.div`
  width: 50%;
  background-color: #b7514b;
`

const BrowserViewStyled = styled(BrowserView)`
  display: flex;
  min-height: 100vh;
  height: 100%;
  margin-bottom: -60px;
`

const MenuWrapperDesktopCarrinho = styled(MenuWrapperDesktop)`
  width: 50%;
  max-width: auto;
  min-width: auto;
`

export default function Carrinho() {
  let history = useHistory();
  const [vendedor] = useRecoilState(vendedorState)
  const { isEmpty, items, cartTotal, removeItem, emptyCart, updateItem, totalItems } = useCart()
  const [loading, setLoading] = useRecoilState(loadingAppState)
  const [, setPromoActive] = useRecoilState(promoActiveState)
  const [activeStep, setActiveStep] = useRecoilState(cartStepState)
  const [card] = useRecoilState(cartaoDeCreditoState)
  const [enderecoDeCobranca, setEnderecoCobranca] = useRecoilState(enderecoDeCobrancaState)
  const [freteEndereco] = useRecoilState(freteState)
  const [, setPedidoFinalizado] = useRecoilState(pedidoFinalizadoState)
  const [agendas] = useRecoilState(agendasState)
  const [, setParcelas] = useRecoilState(parcelasState)
  const [, setLoadingParcelas] = useRecoilState(loadingParcelasState)
  const [cartaoDeCredito, setCartaoDeCredito] = useRecoilState(cartaoDeCreditoState)
  const payloadApi = useRecoilValue(finalizarCompraQuery)
  const validEntrega = useRecoilValue(validEntregaState)
  const validPagamento = useRecoilValue(validPagamentoState)
  const validPagamentoEndereco = useRecoilValue(validEnderecoCobrancaQuery)
  const validCliente = useRecoilValue(validClienteQuery)
  const [tipoCobranca] = useRecoilState(tipoCobrancaState)
  const [aceitoTermos] = useRecoilState(aceitoTermosState)

  function canGoToNextStep() {
    switch (activeStep) {
      case 0:
        return true
      case 1:
        return validEntrega
      case 2:
        return validCliente
      case 3:
        return validPagamento && validPagamentoEndereco
      case 4:
        return aceitoTermos
      default:
        return false
    }
  }

  function pagamentoCartao() {
    gerarTokenCartao(card)
      .then(cardToken => {
        if(cardToken.errors) {
          throw new Error("Error in credit card");
        }

        const payload = serializePayload({
          payloadApi,
          items,
          enderecoDeCobranca,
          cartTotal,
          vendedor,
          paymentOption: cardToken, 
          parcelas: card.parcelas.value, 
        })

        executaPagamento(payload)
      })
      .catch(() => {
        const payloadRollBar = JSON.stringify({
          payloadApi, items, enderecoDeCobranca, cartTotal,
          parcelas: card.parcelas.value, vendedor
        })
        window.onerror(`Erro ao gerar token do cartão: ${payloadRollBar}`, `Carrinho`)
        
        Swal.fire({
          icon: 'error',
          title: 'Houve um erro',
          text: `Houve um erro em validar seu cartão de crédito`,
          footer: `<a target="_blank" href="https://api.whatsapp.com/send?phone=${vendedor.telefone}">Fale direto com seu vendedor</a>`
        })

        setActiveStep(2)
        setLoading(false)
      })
    return
  }

  function pagamentoBoleto() {
    const payload = serializePayload({
      payloadApi,
      items,
      enderecoDeCobranca,
      cartTotal,
      vendedor,
      paymentOption: {type: "boleto"}, 
      parcelas: 1, 
    })

    executaPagamento(payload)
  }

  function pagamentoPix() {
    const payload = serializePayload({
      payloadApi,
      items,
      enderecoDeCobranca,
      cartTotal,
      vendedor,
      paymentOption: {type: "pix"}, 
      parcelas: 1, 
    })

    executaPagamento(payload)
  }

  function executaPagamento(payload) {
    finalizarCompra(payload)
      .then(res => {
        setPedidoFinalizado({...res.data[0]})
        setLoading(false)
        if(res.status === "error") {
          window.onerror(`Erro ao finalizar pedido: ${JSON.stringify(payload)}`, `Carrinho`)
          Swal.fire({
            icon: 'error',
            title: 'Houve algo de errado',
            text: `Houve um erro a finalizar o seu pedido: ${res.message}`,
            footer: `<a target="_blank" href="https://api.whatsapp.com/send?phone=${vendedor.telefone}">Fale direto com seu vendedor</a>`
          })
          setActiveStep(0)
        } else {
          emptyCart()
          setCartaoDeCredito({...cartaoDeCredito, cvc: ''})
          history.push(`/pedido-finalizado`)
        }
      })
      .catch(e =>{
        window.onerror(`Erro ao finalizar pedido: ${JSON.stringify(payload)}`, `Carrinho`)
        Swal.fire({
          icon: 'error',
          title: 'Houve algo de errado',
          text: `Houve um erro a finalizar o seu pedido`,
          footer: `<a target="_blank" href="https://api.whatsapp.com/send?phone=${vendedor.telefone}">Fale direto com seu vendedor</a>`
        })
        setLoading(false)
      })
  }

  function nextStep() {
    scrollToTop()
    if (activeStep === 4) {
      setLoading(true)
      if(tipoCobranca === PAGAMENTO_BOLETO) {
        pagamentoBoleto()
      } if (tipoCobranca === PAGAMENTO_PIX) {
        pagamentoPix()
      } else {
        pagamentoCartao()
      }
      return
    }
    if(canGoToNextStep() && activeStep === 1) {
      setActiveStep(activeStep + 1)
      setEnderecoCobranca({
        ...freteEndereco
      })
      setLoadingParcelas(true)
      getParcelas({valor: cartTotal + payloadApi.frete.valor})
      .then(res => {
        setLoadingParcelas(false)
        setParcelas(res.data[0])
      })
      return
    }
    if(canGoToNextStep()) {
      setActiveStep(activeStep + 1)
    }
  }

  useEffect(() => {
    sanitizeCart({removeItem, items, agendas, updateItem, setPromoActive})
    setActiveStep(0)
  }, [totalItems, agendas])

  return (
    <PageWrapper background={'white'} noPadding>
      <Helmet>
        <title>Carrinho</title>
      </Helmet>
      <MobileView>
        <MenuWrapper color="#b7514b">
          <Menu background={'#b7514b'} />
          <LogoMenu />
          <CartMenu />
        </MenuWrapper>

        <PageContent>
          {isEmpty &&  <CarrinhoVazio />}

          {!isEmpty &&
            <Wrapper>
              <StyledStepper>
                <Stepper
                  steps={[
                    {title: 'Carrinho', onClick: (e) => setActiveStep(0)},
                    {title: 'Entrega', onClick: (e) => setActiveStep(1)},
                    {title: 'Dados', onClick: (e) => setActiveStep(2)},
                    {title: 'Pagamento', onClick: (e) => setActiveStep(3)},
                    {title: 'Finalizar', onClick: (e) => console.log(e)}
                  ]}
                  activeStep={activeStep}
                  completeColor={'#b7514b'}
                  activeColor={'#b7514b'}
                />
              </StyledStepper>

              <Items stepId={0} activeStep={activeStep} />
              <Frete stepId={1} activeStep={activeStep} />
              <Dados stepId={2} activeStep={activeStep} />
              <Pagamento stepId={3} activeStep={activeStep} />
              <Finalizar stepId={4} activeStep={activeStep} />
              <BuyButtonStyled
                onClick={() => {nextStep()}}
                disabled={!canGoToNextStep()}
                color={canGoToNextStep() ? '#b7514b' : 'rgb(153,153,153)'}
              >
                {activeStep === 4 ? 'Comprar!' : 'Próximo'}
              </BuyButtonStyled>
            </Wrapper>
          }
        </PageContent>
      </MobileView>
      <BrowserViewStyled>
        <DivBackground />
        <WrapperBrowser>
          <CenterMenu>
            <MenuWrapperDesktopCarrinho color="#b7514b">
              <MenuDesktop /> 
              <CartMenu />
            </MenuWrapperDesktopCarrinho>
          </CenterMenu>
          <PageContent>
            {isEmpty &&  <CarrinhoVazio />}

            {!isEmpty &&
              <Wrapper>
                <StyledStepper>
                  <Stepper
                    steps={[
                      {title: 'Carrinho', onClick: (e) => setActiveStep(0)},
                      {title: 'Entrega', onClick: (e) => setActiveStep(1)},
                      {title: 'Dados', onClick: (e) => setActiveStep(2)},
                      {title: 'Pagamento', onClick: (e) => setActiveStep(3)},
                      {title: 'Finalizar', onClick: (e) => console.log(e)}
                    ]}
                    activeStep={activeStep}
                    completeColor={'#b7514b'}
                    activeColor={'#b7514b'}
                  />
                </StyledStepper>

                <Items stepId={0} activeStep={activeStep} />
                <Frete stepId={1} activeStep={activeStep} />
                <Dados stepId={2} activeStep={activeStep} />
                <Pagamento stepId={3} activeStep={activeStep} />
                <Finalizar stepId={4} activeStep={activeStep} />
                <BuyButtonStyled
                  onClick={() => {nextStep()}}
                  disabled={!canGoToNextStep()}
                  color={canGoToNextStep() ? '#b7514b' : 'rgb(153,153,153)'}
                >
                  {activeStep === 4 ? 'Comprar!' : 'Próximo'}
                </BuyButtonStyled>
              </Wrapper>
            }
          </PageContent>
        </WrapperBrowser>
      </BrowserViewStyled>

      {loading && <Loading />}
      
    </PageWrapper>
  )
}
