import { useRecoilState } from "recoil";
import { agendasQuery } from "../../atoms/agendasAtom";
import { BackgroundTop } from "../HomeStyles";

export function AgendaHomeBackground({getPrevIndex, getNextIndex, agendaActual, isMobile}) {
  const [agendas] = useRecoilState(agendasQuery)
  const currentAgenda = agendas.find(agenda => agenda.order === agendaActual)
  const prevAgenda = agendas.find(agenda => agenda.order === getPrevIndex())
  const nextAgenda = agendas.find(agenda => agenda.order === getNextIndex())

  return (
    <>
      <BackgroundTop
        id="top-background-prev"
        backgroundImage={prevAgenda.background}
        active={false}
      />
      <BackgroundTop
        id="top-background-actual"
        backgroundImage={currentAgenda.background}
        active={true}
        isMobile={isMobile}
      />
      <BackgroundTop
        id="top-background-next"
        backgroundImage={nextAgenda.background}
        active={false}
      />
    </>
  )
}