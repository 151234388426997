import { Helmet } from "react-helmet-async"
import styled, {css} from "styled-components"
import Menu from "../components/Menu"
import CartMenu from '../components/CartMenu'
import { CenterMenu, FooterWrapper, MenuWrapper, MenuWrapperDesktop } from '../components/HomeStyles'
import { PageWrapper } from '../components/DefaultStyles'
import LogoMenu from "../components/LogoMenu"
import { BrowserView, MobileView } from "react-device-detect"
import MenuDesktop from "../components/MenuDesktop"
import Footer from "../components/Footer"
import TermosDeUsoTexto from '../components/TermosDeUsoTexto'

const BoxContent = styled.div`
  padding: 12px;
  font-weight: 300;
  color: #333;
  font-size: 16px;
  max-width: 1200px;
  margin: auto;
`

const Title = styled.h2`
  font-size: 46px;
  padding: 16px 24px 0 24px;
  margin-bottom: 0;
  font-family: 'Bebas Neue';
  font-weight: 300;
  color: rgba(12,145,156,1);
`

const Content = styled.p`
  padding: 8px 24px;

  ${props => props.center && css({
    textAlign: "center"
  })}
`

export default function TermosDeUso() {
  return (
    <PageWrapper background={'white'}>
      <Helmet>
        <title>Termos de Uso do Site</title>
      </Helmet>

      <MobileView>
        <MenuWrapper color={"rgba(12,145,156, 1)"}>
          <Menu background={'#999'} />
          <LogoMenu />
          <CartMenu />
        </MenuWrapper>
      </MobileView>

      <BrowserView>
        <CenterMenu>
          <MenuWrapperDesktop color={"rgba(12,145,156, 1)"}>
            <LogoMenu />
            <MenuDesktop />
            <CartMenu />
          </MenuWrapperDesktop>
        </CenterMenu>
      </BrowserView>

      <BoxContent>
        <Title>TERMOS DE USO — Agenda Namastê</Title>

        <Content>
          <TermosDeUsoTexto />
        </Content>
      </BoxContent>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </PageWrapper>
  )
}
