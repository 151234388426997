import { useState } from "react"

export function useMobileEvent({up, down, left, right}) {
  const [xDown, setXDown] = useState(null)
  const [yDown, setYDown] = useState(null)
  
  function getTouches(evt) {
    return evt.touches
  }                                                     
                                                                           
  function handleTouchStart(evt) {
    const firstTouch = getTouches(evt)[0];                                      
    setXDown(firstTouch.clientX)
    setYDown(firstTouch.clientY)                                     
  }
  
  function handleTouchMove(evt) {
    if ( ! xDown || ! yDown ) {
        return;
    }
  
    const xUp = evt.touches[0].clientX
    const yUp = evt.touches[0].clientY
  
    const xDiff = xDown - xUp
    const yDiff = yDown - yUp
                                                                          
    if ( Math.abs( xDiff ) > Math.abs( yDiff ) ) {
      if ( xDiff > 0 ) {
        left()
      } else {
        right()
      }                       
    } else {
        if ( yDiff > 0 ) {
          down()
        } else { 
          up()
        }                                                                 
    }
    /* reset values */
    setXDown(null)
    setYDown(null)
  };

  return [handleTouchStart, handleTouchMove]
}