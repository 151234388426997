import styled from 'styled-components'
import { stack as MenuComponent } from 'react-burger-menu'
import { VscMenu } from 'react-icons/vsc'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router'
import { useState } from 'react'
import useInitialFetch from '../hooks/useInitialFetch'
import { useEffect } from 'react'
import { useCart } from 'react-use-cart'

// import 'react-accessible-accordion/dist/fancy-example.css';

const styles = {
  bmBurgerButton: {
    width: '36px',
  },
  bmBurgerBars: {
    background: 'white'
  },
  bmBurgerBarsHover: {
    background: '#CCC'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
    marginLeft: '-12px',
  },
  bmMenu: {
    background: '#373a47',
    padding: '0.8em 0.5em 0',
    fontSize: '30px',
    lineHeight: '70px'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: 'white',
  },
  bmItem: {
    display: 'block',
    color: 'white'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

const MenuWrapper = styled.div`
  position: relative;
  font-family: 'rockwell' !important;
  text-transform: uppercase;
  font-size: 30px;
  line-height: 70px;
`

const MenuCart = styled.div`
  position: relative;
  display: inline-block;
`

const BadgeCartMenu = styled.div`
  position: absolute;
  text-align: center;
  border-radius: 25px;
  color: white;
  min-width: 22px;
  height: 22px;
  padding: 0 8px;
  font-size: 16px;
  right: -14px;
  top: 4px;
  background-color: seagreen;
`

export default function MenuLivro({background}) {
  styles.bmMenu.background = background
  const [isOpen, setIsOpen] = useState(false)
  const { loadVendedor } = useInitialFetch()
  const { id } = useParams();
  const { totalItems } = useCart();

  useEffect(() => {
    loadVendedor()
  }, [])

  useEffect(() => {
    window.scrollTo(0,0)
  }, [id])

  return (
    <MenuWrapper>
      <MenuComponent
        left
        width={ '100%' }
        styles={ styles }
        customBurgerIcon={<VscMenu color={'black'} />}
        isOpen={isOpen}
        onStateChange={(state) => {
          setIsOpen(state.isOpen)
        }}
      >
        <NavLink to={`/carrinho`} onClick={() => setIsOpen(false)}>
          <MenuCart>
            Carrinho <BadgeCartMenu>{totalItems}</BadgeCartMenu>
          </MenuCart>
        </NavLink>

        <NavLink to={`/`} onClick={() => setIsOpen(false)}>
          Outros Produtos
        </NavLink>

        <a target="_blank" rel="noopener noreferrer" href={`https://porquevocemente.com.br`}>
          Voltar para o site
        </a>

      </MenuComponent>
    </MenuWrapper>
  )
}
