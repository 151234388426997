import { useCart } from 'react-use-cart'
import styled from "styled-components"
import BuyButton from '../BuyButton'
import { brlCurrency, getQueroColor } from '../../helpers/agendas'

import { Resumo } from './Resumo'
import { useRecoilState } from 'recoil'
import { agendasState } from '../../atoms/agendasAtom'
import { promoActiveState } from '../../atoms/globalAtom'

const CartLine = styled.div`
  display: flex;
  justify-content: space-evenly;
  border-bottom: solid 1px #999;
  padding: 12px;
`

const QuantWrap = styled.div`
  width: 180px;
`

const Img = styled.img`
  width: 90px;
  height: 110px;
`

const WrapperInfo = styled.div`
  text-align: center;
  justify-content: center;
  display: flex;
  flex-wrap: wrap;

  p {
    margin: 12px 0 0 0;
    width: 100%;
  }

  button {
    margin-top: 0;
  }
`

const PromoText = styled.div`
  background-color: rgba(12,145,156,1);
  color: white;
  text-align: center;
  padding: 8px;
  font-weight: bold;
`

const PromoTextBadge = styled.span`
  display: inline-block;
  margin-left: 4px;
  background-color: red;
  padding: 0 4px;
  color: white;
  font-weight: bold;
`

export default function Items({agendas, stepId, activeStep}) {
  const { items, cartTotal } = useCart()
  const showResume = stepId < activeStep
  const hideEdit = stepId > activeStep
  const [agendasAtom] = useRecoilState(agendasState)
  const [promoActive] = useRecoilState(promoActiveState)

  if(hideEdit) {
    return null
  }

  if(showResume) {
    return <Resumo>
      Agendas: {brlCurrency(cartTotal)}
    </Resumo>
  }

  return <div>
    {/* <PromoText>Acima de 2 agendas, cada uma fica a R$ 80,00</PromoText> */}
    {items.map((item) => {
        return (
          <CartLine key={item.id}>
            <Img src={item.image} alt=""/>
            <WrapperInfo>
              <p>
                {item.nome} - {brlCurrency(item.itemTotal)}
                {(promoActive && !item.isCaderno) && <PromoTextBadge>Promoção</PromoTextBadge>}
              </p>
              <QuantWrap>
                <BuyButton
                  color={getQueroColor(agendasAtom, item.id)}
                  agendaId={item.id}
                />
              </QuantWrap>
            </WrapperInfo>
          </CartLine>
        )
      }
    )}
  </div>
}