import React from 'react'
import { Link } from 'react-router-dom'
import { AgendaCapa, AgendaCapaWrapper, LinkAgenda } from '../HomeStyles'

export function AgendaHomeComponent({agendaActual, agenda, cadernoIndex}) {
  let caderno = () => true

  if (agenda.isCaderno) {
    caderno = cartInfoIndex => cartInfoIndex === cadernoIndex
  }

  return agenda.cartInfo.map((cartInfo, index) => (
    <AgendaCapaWrapper
      key={cartInfo.id}
      id={`agenda-${cartInfo.id}`} 
      active={agendaActual === agenda.order && caderno(index)}
    >
      <Link to={`/detalhes/${cartInfo.slug}`}>
        <LinkAgenda />
      </Link>
      <AgendaCapa
        id={`agenda-${cartInfo.id}`}
        esgotado={cartInfo.esgotado}
        src={cartInfo.image}
        active={agendaActual === agenda.order && caderno(index)}
        alt={cartInfo.alt}
        title={cartInfo.nome}
      />
    </AgendaCapaWrapper>
  ))
}
