import { Fragment } from "react";

export default function TermosDeUsoTexto() {
  return (
    <Fragment>
      <p>Pão na porta, pessoa jurídica de direito privado descreve, através deste documento, as regras de uso do site queropaonaporta.com.br e qualquer outro site, loja ou aplicativo operado pelo proprietário.</p>

      <p>Ao navegar neste website, consideramos que você está de acordo com os Termos de Uso abaixo.</p>

      <p>Caso você não esteja de acordo com as condições deste contrato, pedimos que não faça mais uso deste website, muito menos cadastre-se ou envie os seus dados pessoais.</p>

      <p>Se modificarmos nossos Termos de Uso, publicaremos o novo texto neste website, com a data de revisão atualizada. Podemos alterar este documento a qualquer momento. Caso haja alteração significativa nos termos deste contrato, podemos informá-lo por meio das informações de contato que tivermos em nosso banco de dados ou por meio de notificações.</p>

      <p>A utilização deste website após as alterações significa que você aceitou os Termos de Uso revisados. Caso, após a leitura da versão revisada, você não esteja de acordo com seus termos, favor encerrar o seu acesso.</p>
    </Fragment>
  )
}
