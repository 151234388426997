import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from 'react-overlays'
import { BiXCircle } from "react-icons/bi";
import { agendasQuery } from "../atoms/agendasAtom";
import { useRecoilState } from "recoil";
import TermosDeUsoTexto from '../components/TermosDeUsoTexto'

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const ModalStyled = styled(Modal)`
  width: 100%;
  position: fixed;
  z-index: 1040;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  outline: none;
`;

const ModalContent = styled.div`
  width: 90%;
  max-width: 500px;
  position: relative;
  max-height: 90vh;
  padding: 20px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
  overflow: scroll;
`

const CloseBox = styled.div`
  top: 8px;
  right: 8px;
  font-size: 40px;
  position: absolute;
  cursor: pointer;
`

export default function ModalTermos({isOpen, setIsOpen}) {
  const renderBackdrop = (props) => <Backdrop {...props} />;

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="promocao">
      <ModalStyled
        show={isOpen}
        onHide={() => setIsOpen(false)}
        renderBackdrop={renderBackdrop}
        aria-labelledby="modal-label"
      >
        <ModalContent>
          <CloseBox onClick={closeModal}>
            <BiXCircle />
          </CloseBox>
          <TermosDeUsoTexto />
        </ModalContent>
      </ModalStyled>
    </div>
  )
}

