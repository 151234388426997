import { Link } from "react-router-dom"
import styled from "styled-components"

const Titulo = styled.h2`
  font-family: "Bebas Neue";
  color: white;
  font-size: 30px;
  line-height: 0;
  padding: 0;
  margin: 0;
  font-weight: 300;
`

export default function LogoMenu() {
  return (
    <Link to={`/`}>
      <Titulo>Agenda Namastê</Titulo>
    </Link>
  )
}