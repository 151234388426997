import { BuyButtonInternal, DescriptionAgenda, Detalhes } from "../styled/agenda.styled";
import BuyButton from "./BuyButton";

export default function ProductDescription({agenda, agendaCart}) {
  return (
    <>
      <Detalhes color={agenda.tituloInterno}>
        Detalhes
      </Detalhes>
      <DescriptionAgenda>
        {agendaCart.descricao}
      </DescriptionAgenda>
      <DescriptionAgenda>
        Criada coletivamente por artistas e amigos que moram em comunidade, se trabalham emocionalmente e acreditam na busca da sua verdade para uma vida mais natural de coração. Essa vivência é totalmente refletida em cada página da agenda, através de arte, inspirações, questionamentos e conteúdo reflexivo.
      </DescriptionAgenda>
      <DescriptionAgenda>
        Caderno com arte original dos artistas da Agenda Namastê. Capa em papel couche 300g com aplicação de proteção prolan. 72 página em papel pólen 70g. Disponível em tamanho A5 (14,8cm x 21cm) e A6 (10,5cm x 14,8cm)
      </DescriptionAgenda>
      <BuyButtonInternal>
        <BuyButton
          color={agenda.quero}
          esgotado={agendaCart.esgotado}
          agenda={agendaCart}
          inside={false}
        />
      </BuyButtonInternal>
    </>
  )
}