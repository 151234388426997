import { useRecoilState } from "recoil";
import { agendasQuery } from "../../atoms/agendasAtom";
import BuyButton from '../../components/BuyButton'

export function AgendaHomeButton({agendaActual, cadernoIndex}) {
  const [agendas] = useRecoilState(agendasQuery)
  const currentAgenda = agendas.find(agenda => agenda.order === agendaActual)

  return (
    <BuyButton
      esgotado={currentAgenda.cartInfo[cadernoIndex].esgotado}
      color={currentAgenda.quero}
      agenda={currentAgenda.cartInfo[cadernoIndex]}
    />
  )
}
