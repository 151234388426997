import { serializeCard } from "./serializer";

const apiBase = process.env.REACT_APP_API_BASE
const pagarMe = process.env.REACT_APP_PAGAR_ME
const key = process.env.REACT_APP_KEY

const myHeaders = new Headers();
myHeaders.append("Content-Type", "application/json");

export function opcoesDeFrete(cep, quant) {
  return fetch(`${apiBase}transportadoras/valores?cep=${cep}&itens=[{"id":884,"quantidade":${quant}}]`, {
    headers: myHeaders,
    method: 'GET',
  })
    .then((resp) => resp.json())
}

export function consultaCep(cep) {
  return fetch(`${apiBase}correios/consulta/${cep}`, {
    headers: myHeaders,
    method: 'GET',
  }).then((resp) => resp.json())
}

export function gerarTokenCartao(cardAtom) {
  const card = serializeCard({card: cardAtom})

  return fetch(`${pagarMe}?appId=${key}`, {
    method: 'POST',
    body: JSON.stringify(card)
  }).then((resp) => resp.json())
}

export function getAgendas() {
  return fetch(`${apiBase}produtos`, {
    headers: myHeaders,
    method: 'GET',
  }).then(resp => resp.json())
}

export function finalizarCompra(payload) {
  return fetch(`${apiBase}pedido`, {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(payload)
  }).then((resp) => resp.json())
}

export function carrinhoPerdido(payload) {
  return fetch(`${apiBase}carrinho-perdido`, {
    headers: myHeaders,
    method: 'POST',
    body: JSON.stringify(payload)
  }).then((resp) => resp.json())
}

export function getParcelas({valor}) {
  return fetch(`${apiBase}parcelas?valor=${valor}`, {
    headers: myHeaders,
    method: 'GET',
  }).then(resp => resp.json())
}

export function getVendedor({vendedor}) {
return fetch(`${apiBase}vendedor/${vendedor}`, {
    headers: myHeaders,
    method: 'GET',
  }).then(resp => resp.json())
}
