import { useRecoilState } from "recoil"
import { useLocation } from 'react-router'
import { useCookies } from 'react-cookie'

import { fetchedAgendasState } from "../atoms/agendasAtom"
import { getVendedor } from "../helpers/api"
import { vendedorState } from "../atoms/vendedorAtom"

export default function useInitialFetch() {
  const [fetchedVendedor, setFetchedVendedor] = useRecoilState(fetchedAgendasState)
  const [, setVendedor] = useRecoilState(vendedorState)
  const [cookies, setCookie] = useCookies(['vendedor'])
  const { search } = useLocation();
  const vendedor = new URLSearchParams(search).get('vendedor')

  function loadVendedor() {
    if(!fetchedVendedor) {
      setFetchedVendedor(true)
      let vendedorAtual = vendedor
      if (cookies.vendedor && cookies.vendedor !== 'Agenda') {
        vendedorAtual = cookies.vendedor
      }
      
      getVendedor({vendedor: vendedorAtual || "Agenda"}).then(res => {
        if (res.status === "error") {
          throw new Error("Error when fetch");
        }

        setVendedor(res.data[0])
        setCookie('vendedor', res.data[0].nome, { path: '/'})
      })
      .catch((e) => {
        window.onerror(`Erro ao pegar vendedor: ${vendedorAtual}`, `useInitialFetch`)
        getVendedor({vendedor: "Agenda"}).then(res => {
          if (res.status === "error") {
            throw new Error("Error on the api");
          }
  
          setVendedor(res.data[0])
          setCookie('vendedor', res.data[0].nome, { path: '/'})
        })
        .catch(() => {
          window.onerror(`Erro ao pegar vendedor Agenda`, `useInitialFetch`)
          setFetchedVendedor(true)
        })
      })
    }
  }

  return {loadVendedor}
}