import styled from 'styled-components'
import { FooterWrapperLivro } from './LivroStyles'
import { vendedorState } from '../atoms/vendedorAtom'
import { useRecoilState } from 'recoil'
import { useEffect, useState } from 'react'
import { BsInstagram, BsWhatsapp } from 'react-icons/bs'

const TextBox = styled.div`
  width: 80%;
  margin: auto;
`

const Contato = styled.div`
  font-size: 30px;
  font-family: 'rockwell';
`

const Paragraph = styled.p`
  color: white;
  line-height: 24px;

  a {
    color: white;
  }
`

const BsWhatsappCustom = styled(BsWhatsapp)`
  margin-left: 12px;
`

const SiteNamas = styled.a`
  margin-right: 12px;
`

export default function FooterLivro() {
  const [vendedor] = useRecoilState(vendedorState)
  const [telefone, setTelefone] = useState('')

  useEffect(() => {
    if(vendedor.telefone) {
      setTelefone(vendedor.telefone.replace("-", "").replace("(", "").replace(")", "").replace(" ", ""))
    }
  }, [vendedor])

  return (
    <FooterWrapperLivro>
      <TextBox>
        <Contato>Contato</Contato>
        <Paragraph>Rua da República, 528 - Porto Alegre/RS</Paragraph>
        <Paragraph>
          (51) 3286-7485 {" "}
          <BsWhatsappCustom /> <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=55${telefone}`}> {vendedor.telefone} </a>
        </Paragraph>
        <Paragraph>
          <SiteNamas rel='noreferrer' target='_blank' href='https://www.namaste.com.br'>www.namaste.com.br</SiteNamas>
          <div>
            <BsInstagram /> <a target="_blank" rel="noreferrer" href={`https://www.instagram.com/namastepoars/`}> namastepoars </a>
          </div>
        </Paragraph>
        
      </TextBox>
    </FooterWrapperLivro>
  )
}
