import { selector } from "recoil"
import { validCartaoDeCreditoState } from "./cartaoDeCreditoAtom"
import { tipoCobrancaState } from "./tipoCobrancaAtom"
import { PAGAMENTO_BOLETO, PAGAMENTO_PIX } from "../helpers/constants"

export const validPagamentoState = selector({
  key: 'validPagamentoState',
  get: ({ get }) => {
    const cardStatus = get(validCartaoDeCreditoState)
    const tipoCobranca = get(tipoCobrancaState)

    return (
      cardStatus.number && cardStatus.expiry && cardStatus.cvc && cardStatus.parcelas && cardStatus.holderName
    ) || tipoCobranca === PAGAMENTO_BOLETO || tipoCobranca === PAGAMENTO_PIX
  }
})