import React, { useState } from "react";
import styled from "styled-components";
import { Modal } from 'react-overlays'
import { BiXCircle } from "react-icons/bi";

const Backdrop = styled("div")`
  position: fixed;
  z-index: 1040;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  opacity: 0.5;
`;

const ModalStyled = styled(Modal)`
  width: 100%;
  position: fixed;
  z-index: 1040;
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 40px;
  outline: none;
`;

const ModalContent = styled.div`
  width: 90%;
  max-width: 500px;
  position: relative;
  height: 250px;
  padding: 20px;
  background-color: white;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.5);
`

const CloseBox = styled.div`
  top: 8px;
  right: 8px;
  font-size: 40px;
  position: absolute;
  cursor: pointer;
`

const PromocaoFimDeAno = styled.h3`
  margin: 0;
  padding: 0;
  font-size: 24px;
  /* font-family: 'Roboto'; */
  text-align: center;
  font-weight: bold;
  color: #dd8647;
  margin-top: 24px;
`

const PromoText = styled.p`
  text-align: center;
  font-family: 'Roboto';
  font-weight: 300;
  font-size: 20px;
`

export default function ModalInfo() {
  const [modalIsOpen, setIsOpen] = useState(true);

  const renderBackdrop = (props) => <Backdrop {...props} />;

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className="info">
      <ModalStyled
        show={modalIsOpen}
        onHide={() => setIsOpen(false)}
        renderBackdrop={renderBackdrop}
        aria-labelledby="modal-label"
      >
        <ModalContent>
          <CloseBox onClick={closeModal}>
            <BiXCircle />
          </CloseBox>
          <PromocaoFimDeAno id="promocao-titulo">Informe de Carnaval</PromocaoFimDeAno>
          <PromoText>
            Compras efetuadas entre os dias 17 e 26 de Fevereiro serão enviadas somente no dia 27 de Fevereiro.
          </PromoText>
        </ModalContent>
      </ModalStyled>
    </div>
  )
}

