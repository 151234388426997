import "react-responsive-carousel/lib/styles/carousel.min.css"
import { useEffect } from "react"
import { BrowserView, MobileView} from 'react-device-detect'
import { useRecoilValue } from 'recoil'
import { Link } from 'react-router-dom'
import { useCart } from "react-use-cart"
import livroInterno from '../assets/livro_sombra.png'

import CartMenu from '../components/CartMenu'
import BuyButton from '../components/BuyButton'
import { GoToCart, GoToCartLink, PageContent } from '../components/HomeStyles'
import { agendasByCartSlug, cartInfoByCartSlug } from '../atoms/agendasAtom'
import { hideGoToCart, showGoToCart } from "../animations/home"

import { AgendaTipoBox, CapaImg, CapaImgDesk, CarouselWrapper, CarouselWrapperMobile, PrecoBox, PrecoBoxDesktop } from "../styled/agenda.styled"
import MetaInfo from "../components/MetaInfo"
import { PromoText } from "../components/home-mobile/AgendaHomeWrapper"
import BookDescription from "../components/BookDescription"
import { AgendaPrecoDesktopLivro, AgendaPrecoLivro, BackgroundGrey, BoxInternalWhiteDesktopLivro, BoxInternalWhiteLivro, BuyButtonInternalDesktopLivro, BuyButtonInternalLivro, CenterMenuLivro, GoToCartLivro, MenuRightElements, MenuWrapperDesktopLivro, MenuWrapperLivro, PageBoxLivro, PageContentDesktopLivro, PageWrapperInternalLivro, TitleAgendaDesktopLivro, TitleAgendaMobileLivro } from "../components/LivroStyles"
import LogoMenuLivro from "../components/LogoMenuLivro"
import MenuDesktopLivro from "../components/MenuDesktopLivro"
import MenuLivro from "../components/MenuLivro"
import FooterLivro from "../components/FooterLivro"

export default function Livro() {
  const isPromo = false
  const slug = 'por-que-voce-mente-e-eu-acredito'
  const { isEmpty } = useCart()
  const agenda = useRecoilValue(agendasByCartSlug(slug))
  const agendaCart = useRecoilValue(cartInfoByCartSlug(slug))

  useEffect(() => {
    isEmpty ? hideGoToCart() : showGoToCart()
  }, [isEmpty])

  return (
    <PageWrapperInternalLivro>
      <MetaInfo metaInfos={agendaCart.meta} product={{price: agendaCart.price}} />
      {agendaCart.price !== '-' && <div id='ready' />}

      <Link to={`/carrinho`}>
        <GoToCartLivro>
          <GoToCartLink
            id="go-to-cart"
            color={agenda.menuInterno}
          >
            Comprar
          </GoToCartLink>
        </GoToCartLivro>
      </Link>

      <MobileView>
        <MenuWrapperLivro>
          <MenuLivro background={agenda.menuInterno} />
          <LogoMenuLivro height={40}/>
          <CartMenu color='#b7514b' />
        </MenuWrapperLivro>
      </MobileView>

      <BrowserView>
        <CenterMenuLivro>
          <MenuWrapperDesktopLivro>
            <LogoMenuLivro height={80}/>
            <MenuRightElements>
              <MenuDesktopLivro />
              <CartMenu color='#b7514b' />
            </MenuRightElements>
          </MenuWrapperDesktopLivro>
        </CenterMenuLivro>
      </BrowserView>

      <MobileView>
        <PageContent>
          <TitleAgendaMobileLivro>{agendaCart.nomeInterno}</TitleAgendaMobileLivro>
          <CarouselWrapperMobile>
            <CapaImg
              src={agendaCart.image}
              alt={`Por que você mente e eu acredito`}
              title={agendaCart.nome}
              esgotado={agendaCart.esgotado}
            />
          </CarouselWrapperMobile>
          <PrecoBox>
            <AgendaPrecoLivro>
              R$ {agendaCart.price}
            </AgendaPrecoLivro>
            {(!agenda.isCaderno && !agenda.isBook) && isPromo && <PromoText> 10% Off Lançamento </PromoText>}
          </PrecoBox>
          <BuyButtonInternalLivro>
            <BuyButton
              color={agenda.quero}
              esgotado={agendaCart.esgotado}
              agenda={agendaCart}
              inside={true}
            />
          </BuyButtonInternalLivro>
        </PageContent>
        
        <BoxInternalWhiteLivro>
          <BookDescription agenda={agenda} agendaCart={agendaCart} />
        </BoxInternalWhiteLivro>
      </MobileView>

      <BrowserView>
        <PageBoxLivro>
          <PageContentDesktopLivro>
            <CarouselWrapper>
              <CapaImgDesk
                src={livroInterno}
                alt={`Por que você mente e eu acredito`}
                title={agendaCart.nome}
                esgotado={agendaCart.esgotado}
              />
            </CarouselWrapper>
            <PrecoBoxDesktop>
              <TitleAgendaDesktopLivro>{agendaCart.nome}?</TitleAgendaDesktopLivro>
              <AgendaTipoBox>
                <AgendaPrecoDesktopLivro>
                  R$ {agendaCart.price}
                </AgendaPrecoDesktopLivro>
                {(!agenda.isCaderno && !agenda.isBook) && isPromo && <PromoText> 10% Off Lançamento </PromoText>}
              </AgendaTipoBox>
              <BuyButtonInternalDesktopLivro>
                <BuyButton
                  color={agenda.quero}
                  esgotado={agendaCart.esgotado}
                  agenda={agendaCart}
                  inside={true}
                />
              </BuyButtonInternalDesktopLivro>
            </PrecoBoxDesktop>
          </PageContentDesktopLivro>
        </PageBoxLivro>
        <BackgroundGrey>
          <PageBoxLivro>
            <BoxInternalWhiteDesktopLivro>
              <BookDescription agenda={agenda} agendaCart={agendaCart} />
            </BoxInternalWhiteDesktopLivro>
          </PageBoxLivro>
        </BackgroundGrey>
      </BrowserView>

      <FooterLivro />
    </PageWrapperInternalLivro>
  )
}
