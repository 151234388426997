import { atom } from "recoil"

export const vendedorState = atom({
  key: 'vendedorState',
  default: {
    id: 121, 
    nome: 'Agenda',
    email: '',
    telefone: null,
  }
})

export const fetchedVendedorState = atom({
  key: 'fetchedVendedorState',
  default: false
})
