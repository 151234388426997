import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { HiOutlineShoppingCart } from 'react-icons/hi';
import { useCart } from "react-use-cart";
import { useEffect } from 'react';
import { cartIconPop } from '../animations/cart';
import { useRecoilState } from 'recoil';
import { cartStepState } from '../atoms/cartStepAtom';

const CartWrapper = styled.div`
  position: relative;
  display: inline-block;
  font-size: 25px;
  z-index: 1001;
  margin-top: 4px;
`

const BadgeCart = styled.div`
  position: absolute;
  text-align: center;
  border-radius: 25px;
  color: white;
  min-width: 22px;
  height: 22px;
  padding: 0 8px;
  font-size: 16px;
  left: -14px;
  top: 15px;
  background-color: seagreen;
`

export default function CartMenu({color}) {
  const { totalItems } = useCart();
  const [, setActiveStep] = useRecoilState(cartStepState)

  useEffect(() => {
    cartIconPop()
  }, [totalItems])

  return (
    <Link to={`/carrinho`} onClick={() => {setActiveStep(0)}}>
      <CartWrapper id="cart-menu-icon">
        { totalItems !== 0 &&
          <BadgeCart>{totalItems}</BadgeCart>
        }
        <HiOutlineShoppingCart stroke={color ? color : 'white'}/>
      </CartWrapper>
    </Link>
  )
}