import styled, { css } from 'styled-components'
import { defaultTheme } from '../theme'

export const SectionBox = styled.div`
  width: 100%;
  height: 100%;
  overflow: hidden;
`

export const SectionTitle = styled.div`
  color: white;
  background-size: cover;
  background-position: bottom;
  width: 100%;
  height: 60vh;
  box-shadow: 0px -2px 6px black;
  position: relative;
`

export const BackgroundTop = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  background-size: cover;

  ${props => css({
      backgroundImage: `url(${props.backgroundImage})`,
      opacity: props.active ? 1 : 0,
      backgroundPosition: props.isMobile ? 'bottom' : 'center'
    })
  }
`

export const AgendaBox = styled.div`
  padding: 70px 35px 0 35px;
  display: grid;
  z-index: 2;
  position: relative;
`

export const AgendaBoxDesk = styled.div`
  padding: 85px 35px 0 35px;
  display: flex;
  justify-content: center;
  z-index: 2;
  position: relative;
`

export const AgendaWrapperDesk = styled.div`
  width: 550px;
`

export const TextIndent = styled.div`
  width: 300px;
  margin: auto;
  margin-bottom: 15px;
`

export const TextIndentDesk = styled.div`
  width: 350px;
  margin-top: 20px;
  margin-right: 12px;
`

export const AgendaTitle = styled.div`
  font-size: 65px;
  line-height: 56px;
  
  ${props => css({
      width: props.desktop ? "350px" : "auto",
      textShadow: `3px 3px ${props.shadowColor}`
    })
  }
`

export const AgendaPreco = styled.div`
  display: block;
  font-size: 40px;
  line-height: 40px;
  text-align: left !important;
  white-space: nowrap;
`

export const AgendaTipo = styled.div`
  display: flex;
  align-items: baseline;
`

export const Button = styled.button`
  display: flex;
  align-items: center;
  cursor: pointer;
  background: white;
  font-size: 18px;
  margin: 0;
  padding: 2px 8px;
  width: 90px;
  
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255,255,255, 0.08);

  ${props => css({
      border: `solid 1px ${props.active ? props.color : '#333'}`,
      color: props.active ? props.color : '#333',
      fontFamily: `${defaultTheme.fonts.body}`,
      marginRight: `${props.bottomSpace ? '8px' : '0px'}`
    })
  }
`

export const BuyButtonStyled = styled.button`
  width: 100%;
  font-size: 36px;
  border: none;
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  
  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);

  ${props => css({
      fontFamily: `${defaultTheme.fonts.body}`,
      backgroundColor: props.color,
    })
  }

  ${props => props.inside && css({
      backgroundColor: "white",
      color: props.color
    })
  }
`

export const QuantAgenda = styled.div`
  margin: 0 18px;
`

export const AgendaCapaWrapper = styled.div`
  text-align: center;
  height: 360px;
  position: relative;

  @media(max-height: 590px) {
    height: 285px;
  }

  ${props => css({
    display: props.active ? 'block' : 'none'}
  )}
`

export const LinkAgenda = styled.div`
  position: absolute;
  width: 80%;
  height: 80%;
  left: 12%;
  top: 10%;
  z-index: 4;
`

export const AgendaCapa = styled.img`
  position: relative;
  max-width: 90%;
  max-height: 400px;
  margin: auto;

  ${props => css({
      display: props.active ? 'block' : 'none',
      maxHeight: "460px",
      filter: props.esgotado ? "grayscale(100%)" : "drop-shadow(black 2px 2px 4px);"
    })
  }

  @media(max-height: 590px) {
    max-height: 285px;
  }
`

export const AgendaCapaDesk = styled(AgendaCapa)`
`

export const SectionContent = styled.div`
  background-position-y: 0px;
  background-size: cover;
  width: 100%;
  height: 40vh;
  position: relative;

  ${props => css({
      backgroundImage: `url(${props.backgroundImage})`,
    })
  }
`

export const BuyWrapper = styled.div`
  display: flex;
  align-items: center;

  ${props => css({
      color: props.color || "white",
      justifyContent: props.left ? 'left' : 'space-between',
    })
  }
`

export const NavigateAgendas = styled.div`
  position: absolute;
  z-index: 2;
  display: flex;
  color: black;
  bottom: 0px;
  right: calc(50% - 27px);
  flex-wrap: wrap;
  font-size: 42px;
  width: 54px;
  text-align: center;
  line-height: 9px;
  cursor: pointer;
`

const NavigateAgendasHorizontal = styled.div`
  position: absolute;
  z-index: 3;
  display: flex;
  color: black;
  top: calc(50% - 27px);
  flex-wrap: wrap;
  font-size: 42px;
  width: 43px;
  height: 43px;
  text-align: center;
  line-height: 9px;
  cursor: pointer;
  background-color: white;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  opacity: 0;
`

export const NavigateAgendasRight = styled(NavigateAgendasHorizontal)`
  right: 44px;
`

export const NavigateAgendasLeft = styled(NavigateAgendasHorizontal)`
  left: 44px;
`

export const NextAgendaScroll = styled.div`
  font-size: 20px;
`

export const ArrowNavigate = styled.div`
  flex-basis: 100%;
  line-height: 30px;
`

export const GoToCart = styled.div`
  font-family: 'Bebas Neue';
  position: fixed;
  z-index: 3;
  display: flex;
  color: black;
  right: 0;
  flex-wrap: wrap;
  font-size: 26px;
  text-align: center;
  bottom: calc(50% - 48px);
`

export const GoToCartLink = styled.div`
  opacity: 0;
  line-height: 22px;
  text-orientation: unset;
  writing-mode: vertical-lr;
  transform: rotate(180deg);
  padding: 10px 10px 10px 4px;
  color: white;

  ${props => props.color && css({
      backgroundColor: props.color || 'transparent',
    })
  }
`

export const MenuWrapper = styled.div`
  position: fixed;
  z-index: 4;
  display: flex;
  width: 100%;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: center;

  ${props => props.color && css({
      backgroundColor: props.color || 'transparent',
    })
  }
`

export const MenuWrapperDesktop = styled.div`
  position: fixed;
  z-index: 3;
  display: flex;
  max-width: 1000px;
  min-width: 800px;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: center;
  width: 100%;

  ${props => props.color && css({
      backgroundColor: props.color,
    })
  }
`

export const CenterMenu = styled.div`
  display: flex;
  justify-content: center;
`

export const PageContent = styled.div`
  padding-top: 68px;
`

export const TouchAnimation = styled.div`
  opacity: 0;
  font-size: 36px;
  position: absolute;
  z-index: 1;
  margin-left: calc(50% - 108px);
  margin-top: calc(50% - 30px);
  color: white;
  padding: 10px;
  height: 54px;
  display: flex;
  align-items: center;

  -webkit-backdrop-filter: saturate(180%) blur(20px);
  backdrop-filter: saturate(180%) blur(20px);
  background-color: rgba(255,255,255, 0.08);
`

export const FooterWrapper = styled.div`
  position: absolute;
  bottom: 0;
  width: 100%;
  background-color: rgba(12,145,156, 1);
`
