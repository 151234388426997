import "react-responsive-carousel/lib/styles/carousel.min.css"
import { useEffect } from "react"
import { BrowserView, MobileView} from 'react-device-detect'
import { useParams } from 'react-router'
import { useRecoilValue } from 'recoil'
import { Link } from 'react-router-dom'
import { useCart } from "react-use-cart"

import Menu from "../components/Menu"
import CartMenu from '../components/CartMenu'
import BuyButton from '../components/BuyButton'
import { CenterMenu, GoToCart, GoToCartLink, MenuWrapper, MenuWrapperDesktop, PageContent } from '../components/HomeStyles'
import { agendasByCartSlug, cartInfoByCartSlug } from '../atoms/agendasAtom'
import LogoMenu from '../components/LogoMenu'
import MenuDesktop from '../components/MenuDesktop'
import { hideGoToCart, showGoToCart } from "../animations/home"
import Footer from "../components/Footer"

import { AgendaPreco, AgendaPrecoDesktop, AgendaTipoBox, BoxInternalWhite, BoxInternalWhiteDesktop, BuyButtonInternal, BuyButtonInternalDesktop, CapaImg, CapaImgDesk, CarouselWrapper, CarouselWrapperMobile, DescriptionAgenda, Detalhes, IframeStyled, IframeStyledDesktop, PageBox, PageContentDesktop, PageWrapperInternal, PrecoBox, PrecoBoxDesktop, SpacingContent, TitleAgenda, TitleAgendaDesktop, VideoCenter } from "../styled/agenda.styled"
import ProductDescription from "../components/ProductDescription"
import AgendaDescription from "../components/AgendaDescription"
import MetaInfo from "../components/MetaInfo"
import { PromoText } from "../components/home-mobile/AgendaHomeWrapper"
import BookDescription from "../components/BookDescription"

export default function Agenda() {
  const isPromo = false
  const { slug } = useParams()
  const { isEmpty } = useCart()
  const agenda = useRecoilValue(agendasByCartSlug(slug))

  const agendaCart = useRecoilValue(cartInfoByCartSlug(slug))

  useEffect(() => {
    isEmpty ? hideGoToCart() : showGoToCart()
  }, [isEmpty])

  return (
    <PageWrapperInternal background={agenda.agendaBackground}>
      <MetaInfo metaInfos={agendaCart.meta} product={{price: agendaCart.price}} />
      {agendaCart.price !== '-' && <div id='ready' />}

      <Link to={`/carrinho`}>
        <GoToCart>
          <GoToCartLink
            id="go-to-cart"
            color={agenda.menuInterno}
          >
            Comprar
          </GoToCartLink>
        </GoToCart>
      </Link>

      <MobileView>
        <MenuWrapper color={agenda.menuInterno}>
          <Menu background={agenda.menuInterno} />
          <LogoMenu />
          <CartMenu />
        </MenuWrapper>
      </MobileView>

      <BrowserView>
        <CenterMenu>
          <MenuWrapperDesktop color={agenda.menuInterno}>
            <LogoMenu />
            <MenuDesktop />
            <CartMenu />
          </MenuWrapperDesktop>
        </CenterMenu>
      </BrowserView>

      <MobileView>
        <PageContent>
          <TitleAgenda>{agendaCart.nomeInterno}</TitleAgenda>
          <CarouselWrapperMobile>
            <CapaImg
              src={agendaCart.image}
              alt={`Agenda Namastê ${agendaCart.nome} 2023`}
              title={agendaCart.nome}
              esgotado={agendaCart.esgotado}
            />
          </CarouselWrapperMobile>
          <PrecoBox>
            <AgendaPreco>
              R$ {agendaCart.price}
            </AgendaPreco>
            {(!agenda.isCaderno && !agenda.isBook) && isPromo && <PromoText> 10% Off Lançamento </PromoText>}
          </PrecoBox>
          <BuyButtonInternal>
            <BuyButton
              color={agenda.quero}
              esgotado={agendaCart.esgotado}
              agenda={agendaCart}
              inside={true}
            />
          </BuyButtonInternal>
        </PageContent>
        
        <BoxInternalWhite>
          {(!agenda.isCaderno && !agenda.isBook) && <AgendaDescription agenda={agenda} agendaCart={agendaCart} />}
          {(agenda.isCaderno && !agenda.isBook) && <ProductDescription agenda={agenda} agendaCart={agendaCart} />}
          {agenda.isBook && <BookDescription  agenda={agenda} agendaCart={agendaCart} />}
        </BoxInternalWhite>
      </MobileView>

      <BrowserView>
        <PageBox>
          <PageContentDesktop>
            <CarouselWrapper>
              <CapaImgDesk
                src={agendaCart.imageDesk}
                alt={`Agenda Namastê ${agendaCart.nome} 2023`}
                title={agendaCart.nome}
                esgotado={agendaCart.esgotado}
              />
            </CarouselWrapper>
            <SpacingContent />
            <PrecoBoxDesktop>
              <TitleAgendaDesktop>{agendaCart.nome}</TitleAgendaDesktop>
              <AgendaTipoBox>
                <AgendaPrecoDesktop>
                  R$ {agendaCart.price}
                </AgendaPrecoDesktop>
                {(!agenda.isCaderno && !agenda.isBook) && isPromo && <PromoText> 10% Off Lançamento </PromoText>}
              </AgendaTipoBox>
              <BuyButtonInternalDesktop>
                <BuyButton
                  color={agenda.quero}
                  esgotado={agendaCart.esgotado}
                  agenda={agendaCart}
                  inside={true}
                />
              </BuyButtonInternalDesktop>
            </PrecoBoxDesktop>
          </PageContentDesktop>
          
          <BoxInternalWhiteDesktop>
            {(!agenda.isCaderno && !agenda.isBook) && <AgendaDescription agenda={agenda} agendaCart={agendaCart} />}
            {(agenda.isCaderno && !agenda.isBook) && <ProductDescription agenda={agenda} agendaCart={agendaCart} />}
            {agenda.isBook && <BookDescription  agenda={agenda} agendaCart={agendaCart} />}
          </BoxInternalWhiteDesktop>
        </PageBox>
      </BrowserView>

      <Footer />
    </PageWrapperInternal>
  )
}
