import { useEffect } from "react"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import Menu from "../components/Menu"
import CartMenu from '../components/CartMenu'
import { CenterMenu, MenuWrapper, MenuWrapperDesktop } from '../components/HomeStyles'
import { PageWrapper } from '../components/DefaultStyles'
import { useRecoilState } from "recoil"
import { pedidoFinalizadoState } from "../atoms/pedidoFinalizado"
import { vendedorState } from "../atoms/vendedorAtom"
import { Helmet } from "react-helmet-async"
import LogoMenu from "../components/LogoMenu"
import { BrowserView, MobileView } from "react-device-detect"
import MenuDesktop from "../components/MenuDesktop"
import { BiCopy, BiLinkExternal } from 'react-icons/bi'
import { CopyToClipboard } from 'react-copy-to-clipboard';

const BoxContent = styled.h3`
  padding: 12px;
  font-weight: 300;
  color: #333;
  font-size: 16px;
  max-width: 600px;
  margin: auto;
`

const Title = styled.div`
  margin-top: 60px;
  padding: 16px 0;
  font-size: 24px;
  color: black;
`

const BoxInfo = styled.div`
  border: solid 1px gray;
  padding: 12px;
  margin-bottom: 12px;
`

const BoxTitle = styled.h2`
  padding: 0 0 12px 0;
  margin: 0;
  color: #b7514b;
`

const BoletoLink = styled.a`
  font-size: 20px;
  color: rgba(237,86,2,255);
  display: flex;
  align-items: center;
  padding-bottom: 0;
  margin-bottom: 0;
`

const PixCodeBox = styled.div`
  word-break: break-all;
`

const CopyPastButton = styled.span`
  margin-bottom: 16px;
  display: inline-block;
  padding: 20px;
  background-color: rgba(237,86,2,255);
  color: white;
  cursor: pointer;
  transition: background-color 0.3s ease;

  &:active{
    filter: blur(1px);
    transition: background-color 0.3s ease;
    background-color: rgb(12, 145, 156);
  }
`

const CopyPastButtonWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
`

const QrCodeImage = styled.img`
  margin: auto;
  display: block;
`

export default function PedidoFinalizado() {
  let history = useHistory();
  const [pedidoFinalizado] = useRecoilState(pedidoFinalizadoState)
  const [vendedor] = useRecoilState(vendedorState)

  useEffect(() => {
    if(!pedidoFinalizado.cliente) {
      history.push(`/${vendedor.nome}`)
    }
  }, [])

  if(!pedidoFinalizado.cliente) {
    return null
  }

  return (
    <PageWrapper background={'white'}>
      <Helmet>
        <title>Pedido Finalizado</title>
      </Helmet>

      <MobileView>
        <MenuWrapper color={"rgba(12,145,156, 1)"}>
          <Menu background={'rgba(12,145,156, 1)'}/>
          <LogoMenu />
          <CartMenu />
        </MenuWrapper>
      </MobileView>

      <BrowserView>
        <CenterMenu>
          <MenuWrapperDesktop color={"rgba(12,145,156, 1)"}>
            <LogoMenu />
            <MenuDesktop />
            <CartMenu />
          </MenuWrapperDesktop>
        </CenterMenu>
      </BrowserView>

      <BoxContent>
        <Title>Pedido #{pedidoFinalizado.id}</Title>

        <BoxInfo>
          <BoxTitle>Dados Pessoais</BoxTitle>
          {pedidoFinalizado.cliente.nome} <br />
          {pedidoFinalizado.cliente.telefone} <br />
          {pedidoFinalizado.cliente.cpf} <br />
          {pedidoFinalizado.cliente.email}
        </BoxInfo>

        {pedidoFinalizado.pagamento.transaction_type === "boleto" && <BoxInfo>
          <BoxTitle>Pagamento</BoxTitle>
          <BoletoLink target="_blank" rel="noreferrer" href={pedidoFinalizado.pagamento.pdf}>
            Link para o Boleto
            <BiLinkExternal color={"rgba(237,86,2,255)"}/>
          </BoletoLink><br /> <br />
          
          QRCODE <br />
          <QrCodeImage src={pedidoFinalizado.pagamento.qr_code} alt="QRCODE" />
        </BoxInfo>}

        {pedidoFinalizado.pagamento.transaction_type === "pix" && <BoxInfo>
          <BoxTitle>Pagamento</BoxTitle>
          <CopyPastButtonWrapper>
            <CopyToClipboard text={pedidoFinalizado.pagamento.qr_code}>
              <CopyPastButton>Copia E Cola <BiCopy color={"white"}/></CopyPastButton>
            </CopyToClipboard>
            <PixCodeBox>{pedidoFinalizado.pagamento.qr_code}</PixCodeBox>
          </CopyPastButtonWrapper>
          
          <QrCodeImage src={pedidoFinalizado.pagamento.qr_code_url} alt="QRCODE" />
          <p>Assim que o pagamento for confirmado você irá receber um email com mais informações</p>
        </BoxInfo>}

        {pedidoFinalizado.pagamento.transaction_type === "credit_card" && <BoxInfo>
          <BoxTitle>Pagamento</BoxTitle>
          Cartão de Credito <br />
          Parcelas: {pedidoFinalizado.pagamento.installments} <br />
        </BoxInfo>}
        
        <BoxInfo>
          <BoxTitle>Informações</BoxTitle>
          Whats do seu vendedor <a target="_blank" rel="noreferrer" href={`https://api.whatsapp.com/send?phone=${vendedor.telefone}`}> {vendedor.telefone} </a><br /><br />
          {pedidoFinalizado.envio.nome} - {pedidoFinalizado.cep} <br />
          Entrega: {pedidoFinalizado.endereco}, {pedidoFinalizado.numero}, {pedidoFinalizado.bairro}, {pedidoFinalizado.cidade} - {pedidoFinalizado.estado} - {pedidoFinalizado.cidade} <br /> <br />
          {pedidoFinalizado.itens.map(item => (
            <div key={item.id}>
              x{item.quantidade} - {item.nome}
            </div>
          ))}
        </BoxInfo>

        <BoxInfo>
          <BoxTitle>Informações de Envio</BoxTitle>
          Como qualquer compra online, o envio só será processado após confirmação do pagamento. A postagem ocorrerá em até dois dias úteis a partir dessa confirmação. A partir dai corre o prazo do frete escolhido pelo cliente. Para Clientes com frete grátis, a entrega será processada a partir do pagamento, podendo ser feita em até 7 dias depois do pagamento ser confirmado.
        </BoxInfo>
      </BoxContent>
    </PageWrapper>
  )
}
