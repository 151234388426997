import styled from "styled-components"
import Menu from "../components/Menu"
import CartMenu from '../components/CartMenu'
import { MenuWrapper } from '../components/HomeStyles'
import { PageHeader, PageWrapper } from '../components/DefaultStyles'

const Title = styled.div`
  padding: 16px;
  font-size: 24px;
  color: white;
`

export default function Agenda() {

  return (
    <PageWrapper background={'white'}>
      <MenuWrapper>
        <CartMenu />
        <Menu background={'#999'} />
      </MenuWrapper>
      <Title>Agenda Namaste 2022</Title>

      <PageHeader>Meus Pedidos</PageHeader>
    </PageWrapper>
  )
}
