import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import DropdownMenu, { DropdownItem, DropdownItemGroup } from '@atlaskit/dropdown-menu'
import useInitialFetch from '../hooks/useInitialFetch'
import { useEffect } from 'react'
import { agendasQuery } from '../atoms/agendasAtom'
import { useRecoilState } from 'recoil'

const MenuItemSmall = styled.div`
  display: block;
  color: white;
  font-size: 20px;
`

const MenuItemDrop = styled(MenuItemSmall)`
  color: black;
`

const MenuWrapper = styled.div`
  position: relative;
  font-family: 'Bebas Neue';
  display: flex;
`

const NavLinkStyled = styled(NavLink)`
  margin-right: 16px;
`

const NavLinkStyledInside = styled(NavLinkStyled)`
  display: block;
  height: 40px;
  padding: 8px 20px;
`

const DropdownTrigger = styled.div`
  font-size: 20px;
  cursor: pointer;
  color: white;
  margin-right: 16px;
`

const DropdownItemStyled = styled(DropdownItem)`
  padding: 0 !important;
`

export default function MenuDesktop() {
  const { loadVendedor } = useInitialFetch();
  const [agendas] = useRecoilState(agendasQuery)

  useEffect(() => {
    loadVendedor()
  }, [])

  return (
    <MenuWrapper>
      <DropdownMenu trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
        <DropdownTrigger
          {...providedProps}
          ref={triggerRef}
        >
          Agendas
        </DropdownTrigger>
      )}>
        <DropdownItemGroup>
          {agendas.map(agenda => {
            if(agenda.isCaderno) {
              return null
            }
            return (
              <DropdownItemStyled key={agenda.cartInfo[0].slug}>
                <NavLinkStyledInside to={`/detalhes/${agenda.cartInfo[0].slug}`} >
                  <MenuItemDrop>{agenda.cartInfo[0].nomeHome}</MenuItemDrop>
                </NavLinkStyledInside>
              </DropdownItemStyled>
            )
          })}
        </DropdownItemGroup>
      </DropdownMenu>

      <DropdownMenu trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
        <DropdownTrigger
          {...providedProps}
          ref={triggerRef}
        >
          Cadernos A6
        </DropdownTrigger>
      )}>
        <DropdownItemGroup>
          {agendas.map(agenda => {
            if(!agenda.isCaderno || agenda.nome === 'Cadernos A5') {
              return null
            }

            return (
              agenda.cartInfo.map(caderno => {
                return (
                  <DropdownItemStyled key={caderno.slug}>
                    <NavLinkStyledInside to={`/detalhes/${caderno.slug}`} >
                      <MenuItemDrop>{caderno.nomeHome}</MenuItemDrop>
                    </NavLinkStyledInside>
                  </DropdownItemStyled>
                )
              })
            )
          })}
        </DropdownItemGroup>
      </DropdownMenu>

      <DropdownMenu trigger={({ triggerRef, isSelected, testId, ...providedProps }) => (
        <DropdownTrigger
          {...providedProps}
          ref={triggerRef}
        >
          Cadernos A5
        </DropdownTrigger>
      )}>
        <DropdownItemGroup>
          {agendas.map(agenda => {
            if(!agenda.isCaderno || agenda.nome === 'Cadernos A6') {
              return null
            }

            return (
              agenda.cartInfo.map(caderno => {
                return (
                  <DropdownItemStyled key={caderno.slug}>
                    <NavLinkStyledInside to={`/detalhes/${caderno.slug}`} >
                      <MenuItemDrop>{caderno.nomeHome}</MenuItemDrop>
                    </NavLinkStyledInside>
                  </DropdownItemStyled>
                )
              })
            )
          })}
        </DropdownItemGroup>
      </DropdownMenu>
      <NavLinkStyled to={`/contatos`} >
        <MenuItemSmall>Contatos</MenuItemSmall>
      </NavLinkStyled>
      <NavLinkStyled to={`/namaste`} >
        <MenuItemSmall>Namaste</MenuItemSmall>
      </NavLinkStyled>
      <NavLinkStyled to={`/sobre-a-agenda`} >
        <MenuItemSmall>Sobre a Agenda</MenuItemSmall>
      </NavLinkStyled>
    </MenuWrapper>
  )
}
