import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import useInitialFetch from '../hooks/useInitialFetch'
import { useEffect } from 'react'

const MenuItemSmall = styled.div`
  display: block;
  color: black;
  font-size: 17px;
`

const MenuWrapper = styled.div`
  position: relative;
  font-family: 'rockwell';
  display: flex;
  text-transform: uppercase;
`

const NavLinkStyled = styled(NavLink)`
  margin-right: 16px;
`

const LastLinkStyled = styled.a`
  margin-right: 16px;
`

export default function MenuDesktopLivro() {
  const { loadVendedor } = useInitialFetch();

  useEffect(() => {
    loadVendedor()
  }, [])

  return (
    <MenuWrapper>
      <NavLinkStyled to={`/`} >
        <MenuItemSmall>Outros Produtos</MenuItemSmall>
      </NavLinkStyled>
      <LastLinkStyled target="_blank" rel="noopener noreferrer" href={`https://porquevocemente.com.br`} >
        <MenuItemSmall>Voltar Para o Site</MenuItemSmall>
      </LastLinkStyled>
    </MenuWrapper>
  )
}
