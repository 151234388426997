import { useState, useEffect, useRef } from 'react'
import { ThemeProvider } from 'styled-components'
import TextTransition, { presets } from "react-text-transition"
import { Link } from "react-router-dom"
import { useRecoilState } from 'recoil'
import { MdKeyboardArrowDown, MdKeyboardArrowLeft, MdKeyboardArrowRight, MdTouchApp } from 'react-icons/md'
import { BrowserView, MobileView, isMobile} from 'react-device-detect'
import { useCart } from 'react-use-cart'
import {
  initializeAgenda, changeBackgroundDown,
  changeAgendaUp, changeAgendaDown, showVerMais,
  changeBackgroundUp, hideGoToCart, showGoToCart, swipeLeft, swipeRight, 
} from '../animations/home'
import { useMobileEvent } from '../events/mobile'
import { useDesktopEvent } from '../events/desktop'

import { defaultTheme } from '../theme'

import {
  SectionBox, NavigateAgendas, SectionTitle, TouchAnimation,
  AgendaBox,  AgendaTitle, AgendaPreco, SectionContent,
  GoToCart, GoToCartLink, CenterMenu, BuyWrapper, ArrowNavigate,
  AgendaBoxDesk, TextIndentDesk, AgendaWrapperDesk, MenuWrapper,
  NextAgendaScroll, MenuWrapperDesktop, NavigateAgendasRight, NavigateAgendasLeft,
} from '../components/HomeStyles'
import Menu from '../components/Menu'
import CartMenu from '../components/CartMenu'
import { agendasQuery } from '../atoms/agendasAtom'

import LogoMenu from '../components/LogoMenu'
import MenuDesktop from '../components/MenuDesktop'
import { AgendaHomeComponent } from '../components/home-mobile/AgendaHomeComponent'
import { AgendaHomeWrapper, PromoText, PromoTextValue } from '../components/home-mobile/AgendaHomeWrapper'
import { AgendaHomeBackground } from '../components/home-mobile/AgendaHomeBackground'
import { AgendaHomeButton } from '../components/home-mobile/AgendaHomeButton'
import { AgendaHomeComponentDesk } from '../components/home-desk/AgendaHomeComponentDesk'
import MetaInfo from '../components/MetaInfo'
import { metaInfoHome } from '../helpers/metaInfoHome'

function Home() {
  const isPromo = false
  const { isEmpty } = useCart()
  const [lastVerMais, setLastVerMais] = useState(null)
  const [agendas] = useRecoilState(agendasQuery)
  const [agendaActual, setAgendaActual] = useState(0)
  const [cadernoIndex, setCadernoIndex] = useState(0)
  const wrapperComponent = useRef(null)
  const [scrollEvent] = useDesktopEvent({ up, down, left, right })
  const [handleTouchStartAgenda, handleTouchMoveAgenda] = useMobileEvent({ up, down, left, right })

  let cadernoNome = ''
  
  if(currentAgenda().isCaderno) {
    cadernoNome = `${currentAgenda().cartInfo[cadernoIndex].nomeHome}`
  }

  function showVerMaisTimeOut() {
    clearTimeout(lastVerMais)
    const showVerMaisRef = setTimeout(showVerMais, 5000)
    setLastVerMais(showVerMaisRef)
  }

  function up() {
    setCadernoIndex(0)
    showVerMaisTimeOut()
    changeAgendaDown(getNextIndex(), agendaActual, setAgendaActual, agendas, cadernoIndex)
    changeBackgroundUp(agendas.find(agenda => agenda.order === getNextIndex()))
  }

  function down() {
    setCadernoIndex(0)
    showVerMaisTimeOut()
    changeAgendaUp(getPrevIndex(), agendaActual, setAgendaActual, agendas, cadernoIndex)
    changeBackgroundDown(agendas.find(agenda => agenda.order === getPrevIndex()))
  }

  
  function left() {
    swipeLeft(currentAgenda(), cadernoIndex, setCadernoIndex)
  }

  function right() {
    swipeRight(currentAgenda(), cadernoIndex, setCadernoIndex)
  }

  function getPrevIndex() {
    const prev = agendaActual - 1
    return prev < 0 ? agendas.length - 1 : prev
  }

  function getNextIndex() {
    const next = agendaActual + 1
    return next >= agendas.length ? 0 : next
  }

  function currentAgenda() {
    return agendas.find(agenda => agenda.order === agendaActual)
  }

  useEffect(() => {
    window.scrollTo(0,1)
    initializeAgenda()
    const preventScroll = (e) => e.preventDefault()
    if (wrapperComponent !== null) {
      wrapperComponent.current.addEventListener('DOMMouseScroll', preventScroll, false)
      wrapperComponent.current.addEventListener('touchmove', preventScroll, {passive: false})
      wrapperComponent.current.addEventListener('wheel', preventScroll, {passive: false})
    }
  }, [])

  useEffect(() => {
    isEmpty ? hideGoToCart() : showGoToCart()
  }, [isEmpty])

  return (
    <div
      className="App"
      ref={wrapperComponent}
      onWheel={ e => { scrollEvent(e) }}
      onTouchStart={ e => handleTouchStartAgenda(e)}
      onTouchMove={e => handleTouchMoveAgenda(e) }
    > 
      <MetaInfo metaInfos={metaInfoHome}/>
      {currentAgenda().cartInfo[0].price !== '-' && <div id='ready' />}

      <MobileView>
        <MenuWrapper>
          <Menu background={currentAgenda().menuInterno} />
          <LogoMenu />
          <CartMenu />
        </MenuWrapper>
      </MobileView>

      <BrowserView>
        <CenterMenu>
          <MenuWrapperDesktop>
            <LogoMenu />
            <MenuDesktop />
            <CartMenu />
          </MenuWrapperDesktop>
        </CenterMenu>
      </BrowserView>

      <ThemeProvider theme={defaultTheme}>
        <SectionBox>
          <NavigateAgendas onClick={down}>
            <NextAgendaScroll>Próxima</NextAgendaScroll>
            <ArrowNavigate><MdKeyboardArrowDown color={'#444'} /></ArrowNavigate>
          </NavigateAgendas>
          <NavigateAgendasRight onClick={right} id="toRight" active={currentAgenda().isCaderno}>
            <ArrowNavigate><MdKeyboardArrowRight color={'#444'} /></ArrowNavigate>
          </NavigateAgendasRight>
          <NavigateAgendasLeft onClick={left} id="toLeft" active={currentAgenda().isCaderno}>
            <ArrowNavigate><MdKeyboardArrowLeft color={'#444'} /></ArrowNavigate>
          </NavigateAgendasLeft>
          <Link to={`/carrinho`}>
            <GoToCart>
              <GoToCartLink 
                id="go-to-cart"
                color={currentAgenda().quero}
              >
                Comprar
              </GoToCartLink>
            </GoToCart>
          </Link>
          <SectionTitle>
            <AgendaHomeBackground
              getPrevIndex={getPrevIndex}
              getNextIndex={getNextIndex}
              agendaActual={agendaActual}
              isMobile={isMobile}
            />
            <MobileView>
              <AgendaBox>
                <AgendaHomeWrapper
                  agendaActual={agendaActual}
                  currentAgenda={currentAgenda()}
                  cadernoIndex={cadernoIndex}
                  isPromo={isPromo}
                />
                <div>
                  <TouchAnimation id="touch-agenda-animation">
                    Veja mais
                    <MdTouchApp />
                  </TouchAnimation>

                  {agendas.map(agenda => {
                    return <AgendaHomeComponent
                        key={agenda.order}
                        agendaActual={agendaActual}
                        agenda={agenda}
                        cadernoIndex={cadernoIndex}
                      />
                  })}
                </div>
                <AgendaHomeButton
                  agendaActual={agendaActual}
                  cadernoIndex={cadernoIndex}
                />
              </AgendaBox>
            </MobileView>
            <BrowserView>
              <AgendaBoxDesk>
                <TextIndentDesk>
                  <AgendaTitle desktop>
                    <TextTransition
                      text={currentAgenda().nome}
                      springConfig={presets.gentle}
                      inline />
                  </AgendaTitle>
                  <BuyWrapper left>
                    <AgendaPreco id="agendaPreco">
                      <div>
                        <span style={{position: "relative"}}>R$</span> <TextTransition
                        text={currentAgenda().cartInfo[cadernoIndex].price}
                        springConfig={presets.gentle}
                        inline />
                        {" "}
                        <TextTransition
                          text={cadernoNome}
                          springConfig={presets.gentle}
                          inline />
                        {(!currentAgenda.isCaderno && isPromo) && <PromoTextValue> 90 </PromoTextValue>}
                      </div>
                    </AgendaPreco>
                    {(!currentAgenda().isCaderno && isPromo) && <PromoTextValue margin> 90 </PromoTextValue>}
                  </BuyWrapper>
                  {(!currentAgenda().isCaderno && isPromo) &&  <PromoText> 10% Off Lançamento </PromoText>}
                  <AgendaHomeButton
                    agendaActual={agendaActual}
                    cadernoIndex={cadernoIndex}
                  />
                </TextIndentDesk>
                <AgendaWrapperDesk>
                  {agendas.map(agenda => {
                    return <AgendaHomeComponentDesk
                      key={agenda.order}
                      agendaActual={agendaActual}
                      agenda={agenda}
                      cadernoIndex={cadernoIndex}
                    />
                  })}
                </AgendaWrapperDesk>
              </AgendaBoxDesk>
            </BrowserView>
          </SectionTitle>
          <SectionContent id="bottom-background" backgroundImage={currentAgenda().elementos} />
        </SectionBox>
      </ThemeProvider>
    </div>
  );
}

export default Home;
