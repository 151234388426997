import 'react-phone-number-input/style.css'

import { Fragment, useState, useEffect } from 'react'
import { useCart } from 'react-use-cart'
import styled from "styled-components"
import { useRecoilState, useRecoilValue } from "recoil"
import { CartaoDeCredito } from "./CartaoDeCredito"
import { clienteState } from "../../atoms/clienteAtom"
import { Resumo } from "./Resumo"
import { enderecoDeCobrancaState, validEnderecoCobrancaFieldQuery } from "../../atoms/cartaoDeCreditoAtom"
import { freteState } from '../../atoms/freteAtom'
import { PAGAMENTO_BOLETO, PAGAMENTO_CARTAO, PAGAMENTO_PIX } from '../../helpers/constants'
import {
  FieldSet, FieldSetInline, Label, LabelInline, LabelEnd,
  Input, InputInline, CartaoWrapper, InputMask, OpcaoCheckbox, CheckboxWrapper, PagamentoCheck, LabelPagamento
} from './CarrinhoStyles'
import { carrinhoPerdido } from '../../helpers/api'
import { serializeTelefone } from '../../helpers/serializer'
import { vendedorState } from '../../atoms/vendedorAtom'
import { RiCheckboxBlankCircleLine, RiCheckboxCircleFill } from 'react-icons/ri'
import { BsCashCoin, BsFillCreditCard2BackFill } from 'react-icons/bs'
import { TiDocumentText } from 'react-icons/ti'
import { tipoCobrancaState } from '../../atoms/tipoCobrancaAtom'

const CartaoDeCreditoWrapper = styled.div`
  margin-bottom: 20px;
`

export default function Dados({stepId, activeStep, cartaoCredito}) {
  const showResume = stepId < activeStep
  const hideEdit = stepId > activeStep

  const { items } = useCart()
  const validEndereco = useRecoilValue(validEnderecoCobrancaFieldQuery)
  const [endereco, setEndereco] = useRecoilState(enderecoDeCobrancaState)
  const [mesmoEndereco, setMesmoEndereco] = useState(true)
  const [freteEndereco] = useRecoilState(freteState)
  const [cliente] = useRecoilState(clienteState)
  const [vendedor] = useRecoilState(vendedorState)
  const [tipoCobranca, setTipoCobranca] = useRecoilState(tipoCobrancaState)

  const maskCep = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/]

  function setMesmoEnderecoFields() {
    if (!mesmoEndereco) {
      setEndereco({
        ...freteEndereco
      })
    }
    setMesmoEndereco(!mesmoEndereco)
  }

  useEffect(() => {
    if(stepId === activeStep) {
      const payload = {
        cliente: {
          ...cliente,
          telefone: serializeTelefone(cliente.telefone)
        },
        endereco: freteEndereco,
        items: {...items},
        vendedor
      } 

      carrinhoPerdido(payload).then((res) => {
        if(res.status === "error") {
          window.onerror(`Erro ao guardar carrinho perdido`, `Carrinho perdido`)
        }
        
      })
    }
  }, [activeStep, stepId])

  if(hideEdit) {
    return null
  }

  if(showResume) {
    return <Resumo>
      {tipoCobranca === PAGAMENTO_BOLETO && "Boleto"}
      {tipoCobranca === PAGAMENTO_CARTAO && "Cartão de Crédito"}
      {tipoCobranca === PAGAMENTO_PIX && "PIX"}
    </Resumo>
  }

  return (
    <CartaoWrapper>
      <OpcaoCheckbox
        active={tipoCobranca === PAGAMENTO_CARTAO}
        onClick={() => {
          setTipoCobranca(PAGAMENTO_CARTAO)
        }}
      >
        <CheckboxWrapper>
          {tipoCobranca === PAGAMENTO_CARTAO && <RiCheckboxCircleFill color={"#b7514b"} />}
          {tipoCobranca !== PAGAMENTO_CARTAO && <RiCheckboxBlankCircleLine color={'#ccc'} />}
        </CheckboxWrapper>
        <PagamentoCheck>
          <LabelPagamento>Cartão de crédito</LabelPagamento>
          <BsFillCreditCard2BackFill color={tipoCobranca === PAGAMENTO_CARTAO ? "#b7514b" : '#ccc'} />
        </PagamentoCheck>
      </OpcaoCheckbox>
      <OpcaoCheckbox
        active={tipoCobranca === PAGAMENTO_PIX}
        onClick={() => {
          setTipoCobranca(PAGAMENTO_PIX)
        }}
      >
        <CheckboxWrapper>
          {tipoCobranca === PAGAMENTO_PIX && <RiCheckboxCircleFill color={"#b7514b"} />}
          {tipoCobranca !== PAGAMENTO_PIX && <RiCheckboxBlankCircleLine color={'#ccc'} />}
        </CheckboxWrapper>
        <PagamentoCheck>
          <LabelPagamento>Pix</LabelPagamento>
          <BsCashCoin color={tipoCobranca === PAGAMENTO_PIX ? "#b7514b" : '#ccc'} />
        </PagamentoCheck>
      </OpcaoCheckbox>
      <OpcaoCheckbox
        active={tipoCobranca === PAGAMENTO_BOLETO}
        onClick={() => {
          setTipoCobranca(PAGAMENTO_BOLETO)
        }}
      >
        <CheckboxWrapper>
          {tipoCobranca === PAGAMENTO_BOLETO && <RiCheckboxCircleFill color={"#b7514b"} />}
          {tipoCobranca !== PAGAMENTO_BOLETO && <RiCheckboxBlankCircleLine color={'#ccc'} />}
        </CheckboxWrapper>
        <PagamentoCheck>
          <LabelPagamento>Boleto</LabelPagamento>
          <TiDocumentText color={tipoCobranca === PAGAMENTO_BOLETO ? "#b7514b" : '#ccc'}/>
        </PagamentoCheck>
        
      </OpcaoCheckbox>

      {tipoCobranca === PAGAMENTO_CARTAO && <CartaoDeCreditoWrapper>
        <CartaoDeCredito />
      </CartaoDeCreditoWrapper>}

      <FieldSetInline>
        <LabelInline htmlFor="endereco-cobranca">Endereço de cobranca é o mesmo de entrega</LabelInline>
        <InputInline
          type="checkbox"
          id="endereco-cobranca"
          checked={mesmoEndereco}
          onClick={setMesmoEnderecoFields}
          onChange={() => {}}
        />
      </FieldSetInline>

      {!mesmoEndereco && <Fragment>
        <FieldSet>
          <Label htmlFor="cep">Meu Cep</Label>
          <InputMask
            id="cep"
            type="text"
            $error={!validEndereco.cep}
            mask={maskCep}
            guide={false}
            onChange={e => setEndereco({...endereco, cep: e.target.value})}
          />
        </FieldSet>

        <FieldSet>
          <LabelEnd htmlFor="endereco">endereço</LabelEnd>
          <Input
            id="endereco" type="text"
            value={endereco.endereco}
            error={!validEndereco.endereco}
            onChange={e => setEndereco({...endereco, endereco: e.target.value})}
          />
        </FieldSet>
        <FieldSet>
          <LabelEnd htmlFor="bairro">Bairro</LabelEnd>
          <Input
            id="bairro" type="text"
            value={endereco.bairro}
            error={!validEndereco.bairro}
            onChange={e => setEndereco({...endereco, bairro: e.target.value})}
          />
        </FieldSet>
        <FieldSet>
          <LabelEnd htmlFor="cidade">Cidade</LabelEnd>
          <Input
            id="cidade" type="text"
            value={endereco.cidade}
            error={!validEndereco.cidade}
            onChange={e => setEndereco({...endereco, cidade: e.target.value})}
          />
        </FieldSet>
        <FieldSet>
          <LabelEnd htmlFor="estado">Estado</LabelEnd>
          <Input
            id="estado" type="text"
            value={endereco.estado}
            error={!validEndereco.estado}
            onChange={e => setEndereco({...endereco, estado: e.target.value})}
          />
        </FieldSet>
        <FieldSet>
          <LabelEnd htmlFor="numero">Número</LabelEnd>
          <Input
            id="numero" type="text"
            value={endereco.numero}
            error={!validEndereco.numero}
            onChange={e => setEndereco({...endereco, numero: e.target.value})}
          />
        </FieldSet>
        <FieldSet>
          <LabelEnd htmlFor="numero">Complemento</LabelEnd>
          <Input
            id="numero" type="text"
            value={endereco.complemento}
            onChange={e => setEndereco({...endereco, complemento: e.target.value})}
          />
        </FieldSet>
      </Fragment>}
    </CartaoWrapper>
  )
}
