import { DescriptionAgenda } from "../styled/agenda.styled";
import { DetalhesLivro, TableLivro } from "./LivroStyles";

export default function BookDescription({agenda, agendaCart}) {
  return (
    <>
      <DetalhesLivro color={'black'}>
        Descrição do Produto
      </DetalhesLivro>
      <DescriptionAgenda>
        "Ah, como eu gostaria de viver um grande amor, um amor de verdade..."
      </DescriptionAgenda>
      <DescriptionAgenda>
        Essa afirmação expressa um desejo quase universal. Dificilmente encontraremos uma pessoa que não tenha tido esse anseio em algum momento de sua vida. Por que é tão difícil torná-lo realidade?
        Nestas páginas você verá que, inconscientemente, as pessoas acabam sabotando seus relacionamentos por causa de um equívoco: pensam que amar é agradar o tempo todo. Para evitar conflitos, elas aparentam estar alegres quando estão tristes, fingem sentir orgasmo quando não sentem desejo, começam a lançar mentiras "inofensivas", do tipo: "Se você não vai comigo à festa, não tem graça nenhuma..." Chega, porém, o momento em que a coisa explode, e toda a verdade represada vem à tona.
      </DescriptionAgenda>
      <DescriptionAgenda>
        É com essa simplicidade desconcertante que Milan vai mostrar a você que o segredo para 
        viver um relacionamento pleno é dizer a verdade sempre. Esse não é um caminho fácil, pois a 
        maioria de nós aprendeu a esconder ou a falsear os sentimentos. Mas certamente é a única maneira 
        de criar relacionamentos plenos.
      </DescriptionAgenda>
      <DetalhesLivro color={'black'}>
        Sobre o autor
      </DetalhesLivro>
      <DescriptionAgenda>
      Dono de um entusiasmo pela vida que contagia quem quer que o conheça, o ex-militante político Prem Milan descobriu um jeito diferente de levar adiante seu sonho com um mundo melhor: é fundador e coordenador de um dos centros de meditação e terapias corporais mais ativos do mundo, o Namastê. Com sede em Porto Alegre (RS), é uma história de atuação no Brasil inteiro e fora do país, o Namastê desenvolve técnicas de meditação ativa que enfatizam a expressão emocional através de sons e movimentos. A principal ferramenta de trabalho terapêutico é a bioenergética, cuja finalidade é desbloquear o corpo, permitindo a expressão de emoções, muitas vezes contidas desde a infância. O mestre indiano Osho é um dos grandes inspiradores de Milan, esse gaúcho pai de três filhos que também lidera a Osho Rachana, comunidade terapêutica onde oitenta pessoas vivem juntas buscando o real significado da amizade, da confiança e da entrega total. Com mais de trinta anos de experiência no trabalho com pessoas, esse terapeuta apaixonado pela vida divide agora com os leitores suas descobertas sobre a importância da verdade nos relacionamentos.
      </DescriptionAgenda>
      <DetalhesLivro color={'black'}>
        Dados Técnicos do Produto
      </DetalhesLivro>
      <TableLivro>
        <tr>
          <td>Autor</td>
          <td>Prem Milan</td>
        </tr>
        <tr>
          <td>ISBN</td>
          <td>9786598030506</td>
        </tr>
        <tr>
          <td>Número de páginas</td>
          <td>160</td>
        </tr>
        <tr>
          <td>Data de Lançamento</td>
          <td>28/05/2023</td>
        </tr>
        <tr>
          <td>Acabamento</td>
          <td>Brochura</td>
        </tr>
      </TableLivro>
      {/* <BuyButtonInternal>
        <BuyButton
          color={agenda.quero}
          esgotado={agendaCart.esgotado}
          agenda={agendaCart}
          inside={false}
        />
      </BuyButtonInternal> */}
    </>
  )
}
