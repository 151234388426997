import React from "react"
import { RecoilRoot } from "recoil"
import { CartProvider } from "react-use-cart"
import { HelmetProvider } from 'react-helmet-async'
import { GlobalStyle } from './global.style'
import { CookiesProvider } from 'react-cookie';
import { defaultTheme } from './theme'

import RoutesPage from "./pages/Routes"
import ModalPromo from "./components/ModalPromo"
import ModalInfo from "./components/ModalInfo"

export default function App() {
  return (
    <RecoilRoot>
      <HelmetProvider>
        <CookiesProvider>
          <CartProvider>
            {/* <ModalPromo /> */}
            {/* <ModalInfo /> */}
            <GlobalStyle theme={defaultTheme}/>
            <RoutesPage />
          </CartProvider>
        </CookiesProvider>
      </HelmetProvider>
    </RecoilRoot>
  );
}