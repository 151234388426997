import styled from "styled-components";
import { PageWrapper } from "./DefaultStyles";
import { BoxInternalWhite, BoxInternalWhiteDesktop, BuyButtonInternal, BuyButtonInternalDesktop, Detalhes, TitleAgenda } from "../styled/agenda.styled";
import { AgendaPreco, GoToCart } from "./HomeStyles";

export const MenuWrapperDesktopLivro = styled.div`
  position: fixed;
  z-index: 3;
  display: flex;
  padding: 6px 12px;
  justify-content: space-around;
  align-items: center;
  width: 100%;
  background-color: white;
  box-shadow: #666 0px -2px 6px 2px;
`

export const MenuRightElements = styled.div`
  display: flex;
  align-items: center;
`

export const PageWrapperInternalLivro = styled(PageWrapper)`
  padding-bottom: 0;
  background-color: none;
  color: black;
`

export const CenterMenuLivro = styled.div`
  display: flex;
  justify-content: center;
`

export const MenuWrapperLivro = styled.div`
  position: fixed;
  z-index: 4;
  display: flex;
  width: 100%;
  padding: 6px 12px;
  justify-content: space-between;
  align-items: center;
  background-color: white;
  box-shadow: #666 0px -2px 6px 2px;
`

export const PageBoxLivro = styled.div`
  width: 1200px;
  margin: auto;
  color: black;
`

export const AgendaPrecoDesktopLivro = styled.div`
  margin-right: 50px;
  font-size: 93px;
  color: #b7514b;
  font-family: 'rockwell';
  font-weight: bold;
`

export const TitleAgendaLivro = styled.h2`
  margin-top: 0;
  text-align: left;
  color: black;
  font-size: 40px;
  padding-left: 34px;
  font-weight: 300;
  margin-bottom: 0;
  font-family: 'rockwell';
  font-weight: bold;
`

export const TitleAgendaDesktopLivro = styled(TitleAgendaLivro)`
  padding-left: 0;
`

export const PageContentDesktopLivro = styled.div`
  padding-top: 140px;
  display: flex;
`

export const BuyButtonInternalDesktopLivro = styled(BuyButtonInternalDesktop)`
  width: 200px;
  box-shadow: #999 0px 0px 5px 0px;
`

export const TitleAgendaMobileLivro = styled(TitleAgenda)`
  margin-top: 40px;
  font-family: 'rockwell';
  color: black;
  font-weight: bold;
  line-height: 40px;
  margin-bottom: 20px;
`

export const AgendaPrecoLivro = styled(AgendaPreco)`
  color: #b7514b;
  font-size: 70px;
  font-family: 'rockwell';
  font-weight: bold;
`

export const BuyButtonInternalLivro = styled(BuyButtonInternal)`
  box-shadow: #999 0px 0px 5px 0px;
  padding: 0;
  width: 200px;
  margin: auto;
  margin-top: 35px;
`

export const BoxInternalWhiteDesktopLivro = styled(BoxInternalWhiteDesktop)`
  color: black;
  font-family: 'rockwell';
  background-color: #e5e5e5;
`

export const BoxInternalWhiteLivro = styled(BoxInternalWhite)`
  background-color: #e5e5e5;
  color: black;
  font-family: 'rockwell';
`

export const DetalhesLivro = styled(Detalhes)`
  padding-top: 50px;
  font-weight: bold;
  font-family: 'rockwell';
`

export const TableLivro = styled.table`
  margin: auto;
  width: 90%;
  border: none;
  border-spacing: 0;
  padding-bottom: 80px;

  tr {
    line-height: 40px;
    border: none;

    td {
      border: none;
    }
  }

  tr:nth-child(odd) {
    background-color: white;
  }
`

export const FooterWrapperLivro = styled.div`
  color: white;
  background-color: #b7514b;
  min-height: 240px;
  padding-top: 20px;
  text-transform: uppercase;
  font-family: 'rockwell';
`

export const BackgroundGrey = styled.div`
  background-color: #e5e5e5;
`

export const GoToCartLivro = styled(GoToCart)`
  font-size: 40px;
  bottom: calc(50% - 80px);

  #go-to-cart {
    line-height: 40px;
  }
`