export function brlCurrency(number) {
  const num = typeof number === 'number' ? number : parseFloat(number.replace(',', '.')).toFixed(2)
  return new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' }).format(num)
}

export function getQueroColor(agendas, id) {
  const agenda = agendas.find(agenda => {
    return agenda.cartInfo.find(cartInfo => cartInfo.id === parseInt(id))
  })

  if(agenda) {
    return agenda.quero
  } else {
    return "#EEE"
  }
}

export function sanitizeCart({removeItem, items, agendas, updateItem, setPromoActive}) {
  const promoDeQuantidade = {
    active: false,
    price: 80,
    quantidade: 2
  }

  const idsToRemove = items.reduce((prev, curr) => {
    const agenda = agendas.find(agenda => {
      return agenda.cartInfo.find(cartInfo => cartInfo.id === parseInt(curr.id)) 
    })

    if(agenda === undefined) {
      prev.push(curr.id)
    }
    return prev
  }, [])

  idsToRemove.map(id => removeItem(id))

  const totalAgendas = items.reduce((prev, curr) => {
    if (!curr.isCaderno) {
      return prev + curr.quantity
    }

    return prev
  }, 0)

  if(promoDeQuantidade.active) {
    if(totalAgendas >= promoDeQuantidade.quantidade) {
      setPromoActive(true)
      items.map(item => {
        if(!item.isCaderno) {
          updateItem(item.id, {
            price: 80
          })
        }
      })
    } else {
      setPromoActive(false)
      items.map(item => {
        if(!item.isCaderno) {
          updateItem(item.id, {
            price: 90
          })
        }
      })
    }
  }

}

export function scrollToTop() {
  window.scroll(0,0)
}