import { useState } from "react"

export function useDesktopEvent({up, down}) {
  const [isScrolling, setIsScrolling] = useState(false)

  function scrollEvent(e) {
    if (!isScrolling) {
      const scrollUp = e.deltaY < 0
      if(scrollUp) {
        up()
      } else {
        down()
      }
      setIsScrolling(true)
      setTimeout(() => {
        setIsScrolling(false)
      }, 1500)
    }
  }

  return [scrollEvent]
}
