import { Helmet } from "react-helmet-async"
import styled, { css } from "styled-components"
import { BrowserView, MobileView } from "react-device-detect"
import Menu from "../components/Menu"
import CartMenu from '../components/CartMenu'
import { CenterMenu, FooterWrapper, MenuWrapper, MenuWrapperDesktop } from '../components/HomeStyles'
import { PageWrapper } from '../components/DefaultStyles'
import LogoMenu from "../components/LogoMenu"
import MenuDesktop from "../components/MenuDesktop"

import namaste from '../assets/images/namaste.jpeg'
import Footer from "../components/Footer"

const BoxContent = styled.div`
  padding: 12px;
  font-weight: 300;
  color: #333;
  font-size: 16px;
  max-width: 1200px;
  margin: auto;
`

const Content = styled.p`
  padding: 8px 24px;

  ${props => props.center && css({
    textAlign: "center"
  })}
`

const Title = styled.h2`
  font-size: 46px;
  padding: 24px 24px 0 24px;
  margin-bottom: 0;
  font-family: 'Bebas Neue';
  font-weight: 300;
  color: rgba(12,145,156,1);
`

const AlignImage = styled.div`
  text-align: center;

  img {
    max-height: 400px;
  }
`

export default function Namaste() {
  return (
    <PageWrapper background={'white'}>
      <Helmet>
        <title>Namaste</title>
      </Helmet>

      <MobileView>
        <MenuWrapper color={"rgba(12,145,156, 1)"}>
          <Menu background={'rgba(12,145,156, 1)'} />
          <LogoMenu />
          <CartMenu />
        </MenuWrapper>
      </MobileView>

      <BrowserView>
        <CenterMenu>
          <MenuWrapperDesktop color={"rgba(12,145,156, 1)"}>
            <LogoMenu />
            <MenuDesktop />
            <CartMenu />
          </MenuWrapperDesktop>
        </CenterMenu>
      </BrowserView>

      <BoxContent>
        <Title>Sobre o Namastê</Title>

        <AlignImage>
          <a rel="noreferrer" target="_blank" href="https://www.namaste.com.br" alt="Namastê Poa">
            <img src={namaste} alt='Faixada namastê porto alegre' title='Namastê Poa' />
          </a>
        </AlignImage>

        <Content>
          Namastê é aquilo que não deveria existir. Para quem leu "Admirável Mundo Novo", não deveriam existir os selvagens. Existiram. O Namastê é algo fora da curva, onde as pessoas tentam resgatar a humanidade, a vida. É um lugar onde as pessoas meditam, se trabalham pra crescer. Não é pra ser um pouquinho mais feliz. Não! É pra crescer! Pra ser um ser humano mais completo, mais apaixonante, mais sensual, mais amoroso, sabe? 
        </Content>

        <Content>
          É um lugar que é um desafio. Um desafio para a verdadeira vida do ser humano. O ser humano não foi criado pra sobreviver, ser um sobrevivente, e sim pra ser um “vivente” - como diz o gaúcho. Um vivente. E o Namastê tá aí pra isso, pra nos acordar: "Ei, e a vida? E o sexo? O amor?”. A maioria das pessoas se assusta: “Meu deus, vocês são contra a família!". Eles não entendem que a gente é a favor da vida! A gente é a favor do sexo, do amor, da alegria! O Namastê é a mosca da sopa do Raul, sabe como é? É a mosca da sopa do Raul, que tá aí toda hora pra te dizer: “Ei, a vida é muito mais do que isso!”. A vida tá além disso. Quer saber o que acontece no Namastê? Só sentindo lá dentro do teu coração. 
        </Content>

        <Content>
          O Namastê é um conjunto de pessoas que querem agregar o coração à vida, ao trabalho, àquilo que a gente ama. Trabalhar com o coração mexe com as pessoas, elas não gostam muito. Mas a gente foi muito criativo e ousado. Nós respeitamos as pessoas, mas sem nunca deixar de ser nós mesmos. Nós somos autênticos! E isso, às vezes, traz muita incomodação. Então, nosso sucesso demorou muito. 
        </Content>

        <Content>
          No meio da terapia alternativa, no mundo inteiro, não existe um centro igual ao Namastê. Porto Alegre tem o privilégio de ter o maior centro do Osho do mundo, não em tamanho físico, mas em quantidade de meditações e de pessoas que frequentam só não é maior que o centro de Puna, na Índia. Temos 24 anos mantendo as meditações, mantendo nosso trabalho. Com crise, sem crise, não interessa! Nós estamos ali.
        </Content>

        <Content>
          E com a pandemia de 2020, nos reinventamos e passamos a dar trabalhos online também, que agora você poderá conhecer também. Entre no site do Namastê para ver nossa programação!
        </Content>

        <Content>
          <a rel="noreferrer" target="_blank" href="https://www.namaste.com.br" alt="Namastê Poa"> www.namaste.com.br </a>
        </Content>

      </BoxContent>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </PageWrapper>
  )
}
