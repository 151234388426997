import { atom, selector } from "recoil"

export const cartaoDeCreditoState = atom({
  key: 'cartaoDeCreditoState',
  default: {
    holderName: '',
    number: '',
    expiry: '',
    cvc: '',
    parcelas: 0,
  }
})

export const enderecoDeCobrancaState = atom({
  key: 'enderecoDeCobrancaState',
  default: {
    cep: '',
    endereco: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    numero: ''
  }
})

export const validCartaoDeCreditoState = atom({
  key: 'validCartaoDeCreditoState',
  default: {
    holderName: false,
    number: null,
    expiry: null,
    cvc: null,
    parcelas: false,
  }
})

export const validEnderecoCobrancaQuery = selector({
  key: 'validEnderecoCobrancaQuery',
  get: ({get}) => {
    const frete = get(enderecoDeCobrancaState)

    return frete.endereco !== "" &&
      frete.bairro !== "" &&
      frete.estado !== "" &&
      frete.cidade !== "" &&
      frete.numero !== "" &&
      frete.cep.length === 9
  }
})

export const validEnderecoCobrancaFieldQuery = selector({
  key: 'validEnderecoCobrancaFieldQuery',
  get: ({get}) => {
    const frete = get(enderecoDeCobrancaState)

    return {
      cep: frete.cep.length === 9,
      endereco: frete.endereco !== "",
      bairro: frete.bairro !== "",
      estado: frete.estado !== "",
      cidade: frete.cidade !== "",
      numero: frete.numero !== ""
    }
  }
})
