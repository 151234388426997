import { Fragment } from "react";
import { useCart } from "react-use-cart"
import { BuyButtonStyled, QuantAgenda } from "./HomeStyles";
import { BsPlusCircle, BsDashCircle } from 'react-icons/bs'
import styled from "styled-components";

const WrapperIconLess = styled.div`
  padding-left: 80px;
`

const WrapperIconPlus = styled.div`
  padding-right: 80px;
`

export default function BuyButton({color, agenda, agendaId, inside = false, esgotado = false}) {
  const { addItem, updateItemQuantity, getItem } = useCart()
  
  const id = agendaId !== undefined ? agendaId : agenda.id
  const item = getItem(id)

  return (
    <Fragment>
      { item &&
        <BuyButtonStyled
          id="buy-button"
          color={color}
          inside={inside}
        >
          <WrapperIconLess
            onClick={() => { updateItemQuantity(id, item.quantity - 1) }}
          >
            <BsDashCircle size={18} />
          </WrapperIconLess>
          <QuantAgenda>{item.quantity}</QuantAgenda>
          <WrapperIconPlus
            onClick={() => { addItem(item) }}
          >
            <BsPlusCircle size={18}/>
          </WrapperIconPlus>
        </BuyButtonStyled>
      }

      { item === undefined &&
        <BuyButtonStyled
          id="buy-button"
          color={color}
          onClick={() => {
            if (esgotado) {
              return
            }
            addItem(agenda)
          }}
          inside={inside}
        >
          {esgotado ? 'ESGOTADO' : 'QUERO!'}
        </BuyButtonStyled>
      }
    </Fragment>
  )
}