import { parsePhoneNumber } from 'react-phone-number-input'

export function serializeTelefone(telefone) {
  const tel = parsePhoneNumber(telefone || '')
  if (tel) {
    return {
      pais: tel.countryCallingCode,
      estado: tel.nationalNumber.substring(0,2),
      telefone: tel.nationalNumber.substring(2)
    }
  }
  return ''
}

export function serializePayload({payloadApi, items, paymentOption, enderecoDeCobranca, cartTotal, parcelas, vendedor}) {
  return {
    ...payloadApi,
    items: { ...items },
    payment: {
      ...paymentOption,
      endereco: {...enderecoDeCobranca}
    },
    parcelas,
    vendedorId: vendedor.id,
    total: cartTotal + payloadApi.frete.valor
  }
}

export function serializeCard({ card }) {
  const [exp_month, exp_year] = card.expiry.split('/')

  return {
    type: "card",
    card: {
      number: card.number.replaceAll(" ", ""),
      holder_name: card.holderName.normalize('NFD').replace(/[\u0300-\u036f]/g, ''),
      exp_month: exp_month.trim(),
      exp_year: exp_year.trim(),
      cvv: card.cvc,
      label: "visa"
    }
  }
}