import TextTransition, { presets } from "react-text-transition"
import { useRecoilState } from "recoil"
import styled, { css } from "styled-components"
import { agendasQuery } from "../../atoms/agendasAtom"
import { AgendaPreco, AgendaTitle, BuyWrapper, TextIndent } from "../HomeStyles"

export const PromoText = styled.span`
  font-size: 16px;
  background-color: red;
  padding: 2px 6px;
  font-family: 'Roboto';
  font-weight: bold;
  text-transform: uppercase;
`

export const PromoTextValue = styled(PromoText)`
  text-decoration: line-through;
  font-size: 25px;
  background-color: transparent;
  padding: 0;
  font-weight: 300;

  ${props => css({
      marginLeft: props.margin ? '8px' : 0,
    })
  }
`

const BuyWrapperHome = styled(BuyWrapper)`
  display: block;
`

export function AgendaHomeWrapper({agendaActual, cadernoIndex, isPromo}) {
  const [agendas] = useRecoilState(agendasQuery)
  const currentAgenda = agendas.find(agenda => agenda.order === agendaActual)
  
  let cadernoNome = ''
  
  if(currentAgenda.isCaderno) {
    cadernoNome = `${currentAgenda.cartInfo[cadernoIndex].nomeHome}`
  }

  return (
    <TextIndent>
      <AgendaTitle shadowColor={currentAgenda.tituloHomeSombra}>
        <TextTransition
          text={currentAgenda.nome}
          springConfig={presets.gentle}
          inline />
      </AgendaTitle>
      <BuyWrapperHome color={currentAgenda.precoHome}>
        <AgendaPreco id="agendaPreco">
          <div>
            <span style={{position: "relative"}}>R$</span>
            {" "}
            <TextTransition
              text={currentAgenda.cartInfo[cadernoIndex].price}
              springConfig={presets.gentle}
              inline
            />
            {" "}
            <TextTransition
              text={cadernoNome}
              springConfig={presets.gentle}
              inline />
            {(!currentAgenda.isCaderno && isPromo) && <PromoTextValue> 90 </PromoTextValue>}
          </div>
        </AgendaPreco>
        {(!currentAgenda.isCaderno && isPromo) && <PromoText> 10% Off Lançamento </PromoText>}
      </BuyWrapperHome>
    </TextIndent>
  )
}
