import { atom, selector, selectorFamily } from "recoil"

import fridaFundo from '../assets/images/frida/fundo.jpg'
import fridaElementos from '../assets/images/frida/elementos.jpg'
import fridaImage from '../assets/images/frida/capap.png'
import fridaImageDesk from '../assets/images/frida/capag.png'

import livreFundo from '../assets/images/livre/fundo.jpg'
import livreElementos from '../assets/images/livre/elementos.jpg'
import livreImage from '../assets/images/livre/capap.png'
import livreImageDesk from '../assets/images/livre/capag.png'

import amazoniaFundo from '../assets/images/amazonia/fundo.jpg'
import amazoniaElementos from '../assets/images/amazonia/elementos.jpg'
import amazoniaImage from '../assets/images/amazonia/capap.png'
import amazoniaImageDesk from '../assets/images/amazonia/capag.png'

import darksideFundo from '../assets/images/darkside/fundo.jpg'
import darksideElementos from '../assets/images/darkside/elementos.jpg'
import darksideImage from '../assets/images/darkside/capap.png'
import darksideImageDesk from '../assets/images/darkside/capag.png'

import meninaFundo from '../assets/images/menina/fundo.jpg'
import meninaElementos from '../assets/images/menina/elementos.jpg'
import meninaImage from '../assets/images/menina/capap.png'
import meninaImageDesk from '../assets/images/menina/capag.png'

import livroFundo from '../assets/images/livro/fundo.jpg'
import livroElementos from '../assets/images/livro/elementos.jpg'
import livroImage from '../assets/images/livro/capap.png'
import livroImageDesk from '../assets/images/livro/capag.png'

import chaplinColorP from '../assets/images/cadernos/Chaplin_Cor_A6.png'
import chaplinColorG from '../assets/images/cadernos/Chaplin_Cor_A5.png'
import chaplinPeBP from '../assets/images/cadernos/Chaplin_PB_A6.png'
import chaplinPeBG from '../assets/images/cadernos/Chaplin_PB_A5.png'
import budinhaP from '../assets/images/cadernos/Budinha_A6.png'
import budinhaG from '../assets/images/cadernos/Budinha_A5.png'
import raoniP from '../assets/images/cadernos/Raoni_A6.png'
import raoniG from '../assets/images/cadernos/Raoni_A5.png'
import einsteinP from '../assets/images/cadernos/Einstein_A6.png'
import einsteinG from '../assets/images/cadernos/Einstein_A5.png'
import lhamaP from '../assets/images/cadernos/LhamaP.png'
import lhamaG from '../assets/images/cadernos/LhamaG.png'
import estrelaP from '../assets/images/cadernos/EstrelaP.png'
import estrelaG from '../assets/images/cadernos/EstrelaG.png'
import gilP from '../assets/images/cadernos/Gil_A6.png'
import gilG from '../assets/images/cadernos/Gil_A5.png'
import impulsosP from '../assets/images/cadernos/ImpulsosP.png'
import impulsosG from '../assets/images/cadernos/ImpulsosG.png'
import madonnaP from '../assets/images/cadernos/Madonna_A6.png'
import madonnaG from '../assets/images/cadernos/Madonna_A5.png'
import mafaldaP from '../assets/images/cadernos/Mafalda_A6.png'
import mafaldaG from '../assets/images/cadernos/Mafalda_A5.png'
import meninosP from '../assets/images/cadernos/Clube_A6.png'
import meninosG from '../assets/images/cadernos/Clube_A5.png'
// import raulP from '../assets/images/cadernos/Raul_A6.png'
// import raulG from '../assets/images/cadernos/Raul_A5.png'
import amazoniaP from '../assets/images/cadernos/AmazoniaP.png'
import amazoniaG from '../assets/images/cadernos/AmazoniaG.png'
import dancaP from '../assets/images/cadernos/DancaP.png'
import dancaG from '../assets/images/cadernos/DancaG.png'
import mujicaP from '../assets/images/cadernos/MujicaP.png'
import mujicaG from '../assets/images/cadernos/MujicaG.png'
import serFelizP from '../assets/images/cadernos/SerFelizP.png'
import serFelizG from '../assets/images/cadernos/SerFelizG.png'
import fridaP from '../assets/images/cadernos/FridaP.png'
import fridaG from '../assets/images/cadernos/SerFelizG.png'
import amarP from '../assets/images/cadernos/AmarP.png'
import amarG from '../assets/images/cadernos/AmarG.png'

import cadernosFundo from '../assets/images/cadernos/cadernosFundo.jpg'
import cadernoElementos from '../assets/images/cadernos/elementos.jpg'

const agendasDefault = [
  {
    order: 6,
    isCaderno: false,
    isBook: false,
    background: meninaFundo,
    nome: "Menina",
    quero: "#03B903",
    precoHome: "rgb(255 255 255)",
    typeAgendaButton: 'rgba(9,68,33,255)',
    menuInterno: "#b05ebd",
    tituloInterno: "#b05ebd",
    tituloHomeSombra: "#525a9f",
    agendaBackground: "#216386",
    elementos: meninaElementos,
    textoInterno: `Essa capa é para quem não vai se contentar com pouco em 2022. Lúdica e inspiradora, vai lembrar você a cada dia sobre a importância de ser inteiro e amar com toda sua vontade!`,
    cartInfo: [{
        id: 1910,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "Menina",
        nomeHome: "Menina",
        alt: "Agenda Namastê Menina 2023",
        slug: "agenda-namaste-2023-menina",
        tipo: "espiral",
        image: meninaImage,
        imageDesk: meninaImageDesk,
        esgotado: true,
        isCaderno: false,
        isBook: false,
    }]
  },
  {
    order: 4,
    isCaderno: false,
    isBook: false,
    background: livreFundo,
    nome: "Livre",
    quero: "rgb(223 187 10)",
    precoHome: "rgb(255 215 34)",
    typeAgendaButton: 'rgba(9, 11, 117, 0.8)',
    menuInterno: "#dd8647",
    tituloInterno: "#dd8647",
    tituloHomeSombra: "#525a9f",
    agendaBackground: "#e15d36",
    elementos: livreElementos,
    textoInterno: ``,
    cartInfo: [{
      id: 1908,
      price: '-',
      precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
      nome: "Livre",
      nomeHome: "Livre",
      alt: "Agenda Namastê Livrwe 2023",
      slug: "agenda-namaste-2023-livre",
      tipo: "espiral",
      image: livreImage,
      imageDesk: livreImageDesk,
      esgotado: true,
      isCaderno: false,
      isBook: false,
    }]
  },
  {
    order: 3,
    isCaderno: false,
    isBook: false,
    background: darksideFundo,
    nome: "Dark Side",
    quero: "rgba(237,86,2,255)",
    precoHome: "rgba(26,57,79,255)",
    typeAgendaButton: 'rgba(26,57,79,255)',
    menuInterno: "rgba(237,86,2,255)",
    tituloInterno: "#000",
    tituloHomeSombra: "#525a9f",
    agendaBackground: "#000",
    elementos: darksideElementos,
    textoInterno: ``,
    cartInfo: [{
      id: 1909,
      price: '-',
      precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
      nome: "Dark Side",
      nomeHome: "Dark Side",
      alt: "Agenda Namastê Dark Side 2023",
      slug: "agenda-namaste-2023-dark-side",
      tipo: "espiral",
      image: darksideImage,
      imageDesk: darksideImageDesk,
      esgotado: true,
      isCaderno: false,
      isBook: false,
    }]
  },
  {
    order: 0,
    isCaderno: false,
    isBook: false,
    background: amazoniaFundo,
    nome: "Amazônia",
    quero: "rgba(15,175,168,255)",
    precoHome: "white",
    typeAgendaButton: 'white',
    menuInterno: "rgba(12,145,156, 1)",
    tituloInterno: "rgba(12,145,156, 1)",
    tituloHomeSombra: "#525a9f",
    agendaBackground: "rgba(16,185,176, 1)",
    elementos: amazoniaElementos,
    textoInterno: `Colorida e natural, essa capa encanta pelas cores e natureza viva pintadas nela. A menina indígena com olhar forte e inocente também inspira a você a buscar sua naturalidade, seu ser menos corrompido e mais livre!`,
    cartInfo: [{
      id: 1907,
      price: '-',
      precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
      nome: "Amazônia",
      nomeHome: "Amazônia",
      nomeInterno: "Amazônia 2023",
      alt: "Agenda Namastê Amazônia 2023",
      slug: "agenda-namaste-2023-amazonia",
      tipo: "espiral",
      image: amazoniaImage,
      imageDesk: amazoniaImageDesk,
      esgotado: true,
      isCaderno: false,
      isBook: false,
    }]
  },
  {
    order: 5,
    isCaderno: false,
    isBook: false,
    background: fridaFundo,
    nome: "Frida Kahlo",
    quero: "rgba(158, 0, 57, 0.8)",
    precoHome: "rgba(158, 0, 57, 0.8)",
    typeAgendaButton: '#9e0039',
    menuInterno: "rgba(98, 0, 37, 1)",
    tituloInterno: "rgba(98, 0, 37, 1)",
    tituloHomeSombra: "#525a9f",
    agendaBackground: "rgba(158, 0, 57, 1)",
    elementos: fridaElementos,
    textoInterno: "Esta é a capa da Frida. Determinada, criativa e referência de força feminina. Frida é nossa capa pelo quinto ano consecutivo e faz sucesso pelas cores, estampas e olhar forte com presença.",
    cartInfo: [{
      id: 1906,
      price: '-',
      precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
      nome: "Frida",
      nomeHome: "Frida Kahlo",
      alt: "Agenda Namastê Frida Kahlo Espiral 2023",
      slug: "agenda-namaste-2023-frida-kahlo",
      tipo: "espiral",
      image: fridaImage,
      imageDesk: fridaImageDesk,
      esgotado: true,
      isCaderno: false,
      isBook: false,
    }]
  },
  {
    order: 2,
    isCaderno: true,
    isBook: false,
    background: cadernosFundo,
    nome: "Cadernos A5",
    quero: "rgb(157,11,94)",
    precoHome: "rgb(255, 255, 255)",
    typeAgendaButton: 'red',
    menuInterno: "#525a9f",
    tituloInterno: "#525a9f",
    tituloHomeSombra: "#525a9f",
    agendaBackground: "rgb(4,4,20)",
    elementos: cadernoElementos,
    cartInfo: [
      {
        id: 2340,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Einstein",
        alt: "",
        slug: "caderno-einstein-a5",
        tipo: "caderno",
        descricao: '',
        image: einsteinP,
        imageDesk: einsteinG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2344,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Chaplin Colorido",
        alt: "",
        slug: "caderno-chaplin-colorido-a5",
        tipo: "caderno",
        descricao: '',
        image: chaplinColorP,
        imageDesk: chaplinColorG,
        esgotado: true,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2352,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Cacique Raoni",
        alt: "",
        slug: "caderno-cacique-raoni-a5",
        tipo: "caderno",
        descricao: '',
        image: raoniP,
        imageDesk: raoniG,
        esgotado: true,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2334,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Budinha",
        alt: "",
        slug: "caderno-budinha-a5",
        tipo: "caderno",
        descricao: '',
        image: budinhaP,
        imageDesk: budinhaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 1655,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Enchi o Saco",
        alt: "",
        slug: "caderno-enchi-o-saco-a5",
        tipo: "caderno",
        descricao: '',
        image: lhamaP,
        imageDesk: lhamaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 1905,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Estrela",
        alt: "",
        slug: "caderno-estrela-a5",
        tipo: "caderno",
        descricao: '',
        image: estrelaP,
        imageDesk: estrelaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2348,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Gilberto Gil",
        alt: "",
        slug: "caderno-gil-a5",
        tipo: "caderno",
        descricao: '',
        image: gilP,
        imageDesk: gilG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 1841,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Impulsos",
        alt: "",
        slug: "caderno-impulsos-a5",
        tipo: "caderno",
        descricao: '',
        image: impulsosP,
        imageDesk: impulsosG,
        esgotado: true,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2338,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Madonna",
        alt: "",
        slug: "caderno-madonna-a5",
        tipo: "caderno",
        descricao: '',
        image: madonnaP,
        imageDesk: madonnaG,
        esgotado: true,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2350,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Mafalda",
        alt: "",
        slug: "caderno-mafalda-a5",
        tipo: "caderno",
        descricao: '',
        image: mafaldaP,
        imageDesk: mafaldaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2346,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Meninos",
        alt: "",
        slug: "caderno-meninos-a5",
        tipo: "caderno",
        descricao: '',
        image: meninosP,
        imageDesk: meninosG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      // {
      //   id: 2336,
      //   price: '-',
      //   precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
      //   nome: "",
      //   nomeHome: "Rual",
      //   alt: "",
      //   slug: "caderno-raul-a5",
      //   tipo: "caderno",
      //   descricao: '',
      //   image: raulP,
      //   imageDesk: raulG,
      //   esgotado: true,
      //   isCaderno: true,
      //   isBook: false,
      // },
      {
        id: 2342,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Chaplin P&B",
        alt: "",
        slug: "caderno-chaplin-p-and-b-a5",
        tipo: "caderno",
        descricao: '',
        image: chaplinPeBP,
        imageDesk: chaplinPeBG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 1843,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Dançar",
        alt: "",
        slug: "caderno-dancar-a5",
        tipo: "caderno",
        descricao: '',
        image: dancaP,
        imageDesk: dancaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 1651,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Amazônia",
        alt: "",
        slug: "caderno-india-a5",
        tipo: "caderno",
        descricao: '',
        image: amazoniaP,
        imageDesk: amazoniaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
    ]
  },
  {
    order: 1,
    isCaderno: true,
    isBook: false,
    background: cadernosFundo,
    nome: "Cadernos A6",
    quero: "rgb(157,11,94)",
    precoHome: "rgb(255, 255, 255)",
    typeAgendaButton: '#cec009',
    menuInterno: "#525a9f",
    tituloInterno: "#525a9f",
    tituloHomeSombra: "#525a9f",
    agendaBackground: "rgb(4,4,20)",
    elementos: cadernoElementos,
    cartInfo: [
      {
        id: 2353,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Cacique Raoni",
        alt: "",
        slug: "caderno-cacique-raoni-a6",
        tipo: "caderno",
        descricao: '',
        image: raoniP,
        imageDesk: raoniG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2341,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Einstein",
        alt: "",
        slug: "caderno-einstein-a6",
        tipo: "caderno",
        descricao: '',
        image: einsteinP,
        imageDesk: einsteinG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2345,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Chaplin Colorido",
        alt: "",
        slug: "caderno-chaplin-colorido-a6",
        tipo: "caderno",
        descricao: '',
        image: chaplinColorP,
        imageDesk: chaplinColorG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2343,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Chaplin P&B",
        alt: "",
        slug: "caderno-chaplin-pb-a6",
        tipo: "caderno",
        descricao: '',
        image: chaplinPeBP,
        imageDesk: chaplinPeBG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2335,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Budinha",
        alt: "",
        slug: "caderno-budinha-a6",
        tipo: "caderno",
        descricao: '',
        image: budinhaP,
        imageDesk: budinhaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 1654,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Enchi o Saco",
        alt: "",
        slug: "caderno-enchi-o-saco-a6",
        tipo: "caderno",
        descricao: '',
        image: lhamaP,
        imageDesk: lhamaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 1904,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Estrela",
        alt: "",
        slug: "caderno-estrela-a6",
        tipo: "caderno",
        descricao: '',
        image: estrelaP,
        imageDesk: estrelaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2349,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Gilberto Gil",
        alt: "",
        slug: "caderno-gil-a5",
        tipo: "caderno",
        descricao: '',
        image: gilP,
        imageDesk: gilG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 1842,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Impulsos",
        alt: "",
        slug: "caderno-impulsos-a6",
        tipo: "caderno",
        descricao: '',
        image: impulsosP,
        imageDesk: impulsosG,
        esgotado: true,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2339,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Madonna",
        alt: "",
        slug: "caderno-madonna-a6",
        tipo: "caderno",
        descricao: '',
        image: madonnaP,
        imageDesk: madonnaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2351,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Mafalda",
        alt: "",
        slug: "caderno-mafalda-a6",
        tipo: "caderno",
        descricao: '',
        image: mafaldaP,
        imageDesk: mafaldaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 2347,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Meninos",
        alt: "",
        slug: "caderno-meninos-a6",
        tipo: "caderno",
        descricao: '',
        image: meninosP,
        imageDesk: meninosG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      // {
      //   id: 2337,
      //   price: '-',
      //   precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
      //   nome: "",
      //   nomeHome: "Rual",
      //   alt: "",
      //   slug: "caderno-raul-a6",
      //   tipo: "caderno",
      //   descricao: '',
      //   image: raulP,
      //   imageDesk: raulG,
      //   esgotado: false,
      //   isCaderno: true,
      //   isBook: false,
      // },
      {
        id: 1840,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Mujica",
        alt: "",
        slug: "caderno-mujica-a6",
        tipo: "caderno",
        descricao: '',
        image: mujicaP,
        imageDesk: mujicaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 1903,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Ser Feliz",
        alt: "",
        slug: "caderno-ser-feliz-a6",
        tipo: "caderno",
        descricao: '',
        image: serFelizP,
        imageDesk: serFelizG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 343,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Frida",
        alt: "",
        slug: "caderno-frida-a6",
        tipo: "caderno",
        descricao: '',
        image: fridaP,
        imageDesk: fridaG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
      {
        id: 346,
        price: '-',
        precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
        nome: "",
        nomeHome: "Amar",
        alt: "",
        slug: "caderno-a6",
        tipo: "caderno",
        descricao: '',
        image: amarP,
        imageDesk: amarG,
        esgotado: false,
        isCaderno: true,
        isBook: false,
      },
    ]
  },
  {
    order: 7,
    isCaderno: false,
    isBook: true,
    background: livroFundo,
    nome: "Livro",
    quero: "#c34442",
    precoHome: "white",
    typeAgendaButton: 'white',
    menuInterno: "#c34442",
    tituloInterno: "#e3a70b",
    tituloHomeSombra: "#525a9f",
    agendaBackground: "#e3a70b",
    elementos: livroElementos,
    textoInterno: `Colorida e natural, essa capa encanta pelas cores e natureza viva pintadas nela. A menina indígena com olhar forte e inocente também inspira a você a buscar sua naturalidade, seu ser menos corrompido e mais livre!`,
    cartInfo: [{
      id: 2517,
      price: '-',
      precoQuantidade: [{quantidadeMinima: '100', preco: '90'}],
      nome: "Livro",
      nomeHome: "Livro",
      nomeInterno: "Por que você mente e eu acredito?",
      alt: "Por que você mente e eu acredito?",
      slug: "por-que-voce-mente-e-eu-acredito",
      tipo: "espiral",
      image: livroImage,
      imageDesk: livroImageDesk,
      esgotado: false,
      isCaderno: false,
      isBook: true,
    }]
  },
]

// 0 primeira, 6 segunda, 5 terceira...

//Menina
agendasDefault[0].order = 5

//Livre
agendasDefault[1].order = 4

//DarkSide
agendasDefault[2].order = 3

//Amazonia
agendasDefault[3].order = 2

//Frida
agendasDefault[4].order = 1

//Caderno a5
agendasDefault[5].order = 7

//Caderno a6
agendasDefault[6].order = 6

//Livro
agendasDefault[7].order = 0

export const fetchedAgendasState = atom({
  key: 'fetchedAgendasState',
  default: false
})

export const agendasState = atom({
  key: 'agendasState',
  default: agendasDefault
})

export const agendasQuery = selector({
  key: 'agendasQuery',
  get: ({get}) => get(agendasState),
  set: ({set}, agendasApi) => {
    const newAgendas = agendasDefault.map(agenda => {
      let cartInfoWithPrice = []

      cartInfoWithPrice = agenda.cartInfo.map(cartInfo => {
        const actualProduct = agendasApi.find(agendaApi => agendaApi.id === cartInfo.id)
        return {
          ...cartInfo,
          price: parseInt(actualProduct.preco),
          precoQuantidade: actualProduct.precoQuantidade,
          nome: actualProduct.nome,
          slug: actualProduct.slug,
          descricao: actualProduct.descricao,
          alt: actualProduct.nome,
          meta: actualProduct.meta
        }}
      )

      return {
        ...agenda,
        cartInfo: cartInfoWithPrice
      }
    })

    set(agendasState, newAgendas)
  }
})

export const agendasByCartId = selectorFamily({
  key: 'agendasByCartId',
  get: (id) => ({get}) => {
    const agendas = get(agendasState)

    return agendas.find(agenda => {
      return agenda.cartInfo.find(cartInfo => cartInfo.slug === parseInt(id))
    })
  }
})

export const agendasByCartSlug = selectorFamily({
  key: 'agendasByCartSlug',
  get: (slug) => ({get}) => {
    const agendas = get(agendasState)

    return agendas.find(agenda => {
      return agenda.cartInfo.find(cartInfo => cartInfo.slug === slug)
    })
  }
})

export const cartInfoByCartSlug = selectorFamily({
  key: 'cartInfoByCartSlug',
  get: (slug) => ({get}) => {
    const agendas = get(agendasState)

    const agenda = agendas.find(agenda => {
      return agenda.cartInfo.find(cartInfo => cartInfo.slug === slug)
    })

    return agenda.cartInfo.find(cartInfo => cartInfo.slug === slug)
  }
})
