import styled, { css } from "styled-components"
import { PageWrapper } from '../components/DefaultStyles'

export const PageWrapperInternal = styled(PageWrapper)`
  padding-bottom: 0;
`

export const TitleAgenda = styled.h2`
  margin-top: 0;
  text-align: left;
  color: white;
  font-size: 40px;
  font-family: 'Bebas Neue';
  padding-left: 34px;
  font-weight: 300;
  margin-bottom: 0;
`

export const TitleAgendaDesktop = styled(TitleAgenda)`
  padding-left: 0;
`

export const CarouselWrapperMobile = styled.div`
  margin: auto;
  width: 85%;
  text-align: center;
  height: 440px;
`

export const DescriptionAgenda = styled.p`
  padding: 8px 34px;

  ${props => props.center && css({
    textAlign: "center"
  })}
`

export const IframeStyled = styled.iframe`
  width: auto;
  height: 480px;
  border: none;
`

export const VideoCenter = styled.div`
  display: flex;
  justify-content: center;
`

export const IframeStyledDesktop = styled.iframe`
  width: 700px;
  height: 700px;
  margin: auto;
  border: none;
`

export const BuyButtonInternal = styled.div`
  padding: 12px 34px;
`

export const AgendaPreco = styled.div`
  font-size: 40px;
  color: white;
`

export const AgendaPrecoDesktop = styled(AgendaPreco)`
  margin-right: 50px;
`

export const AgendaTipoBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const Button = styled.div`
  background-color: white;
  display: flex;
  align-items: center;
  cursor: pointer;
  font-size: 18px;
  margin: 0;
  padding: 2px 8px;
  width: 90px;
  height: 27px;

  ${props => css({
    textAlign: "center",
    color: props.active ? props.color : '#333',
    marginRight: `${props.rightSpace ? '8px' : '0px'}`
  })}
`

export const ButtonsBox = styled.div`
  display: flex;
`

export const PrecoBox = styled.div`
  padding: 0 34px;
  font-family: 'Bebas Neue';
  font-weight: 300;
  display: flex;
  align-items: center;
  justify-content: space-between;
`

export const CarouselWrapper = styled.div`
  margin: auto;
  width: 45%;
`

export const PrecoBoxDesktop = styled(PrecoBox)`
  display: block;
  width: 45%;
`

export const SpacingContent = styled.div`
  width: 10%;
`

export const BuyButtonInternalDesktop = styled(BuyButtonInternal)`
  padding: 0;
`

export const Detalhes = styled.h2`
  font-size: 46px;
  padding: 24px 34px 0 34px;
  margin-bottom: 0;
  font-family: 'Bebas Neue';
  font-weight: 300;

  ${props => css({
    color: props.color
  })}
`

export const BoxInternalWhite = styled.div`
  background-color: white;
  color: #333;
`

export const BoxInternalWhiteDesktop = styled(BoxInternalWhite)`
  background-color: white;
  color: #333;
  margin-left: -10%;
  margin-right: -10%;
  padding: 0 10%;
`

export const PageContentDesktop = styled.div`
  padding-top: 80px;
  display: flex;
`

export const PageBox = styled.div`
  width: 1200px;
  margin: auto;
`

export const CapaImg = styled.img`
  ${props => css({
    filter: props.esgotado ? "grayscale(100%)" : "none"
  })}
  max-height: 440px;
`

export const CapaImgDesk = styled(CapaImg)`
  width: 100%;
  max-height: 100%;
`
