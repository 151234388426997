import { atom, selector } from "recoil"

export const freteState = atom({
  key: 'freteState',
  default: {
    cep: '',
    endereco: '',
    complemento: '',
    bairro: '',
    cidade: '',
    estado: '',
    numero: ''
  }
})

export const valorFreteState = atom({
  key: 'valorFreteState',
  default: {
    id: null,
    valor: 0
  }
})

export const validEntregaState = selector({
  key: 'validEntregaState',
  get: ({get}) => {
    const frete = get(freteState)
    const valorFrete = get(valorFreteState)

    return frete.endereco !== "" &&
      frete.bairro !== "" &&
      frete.estado !== "" &&
      frete.cidade !== "" &&
      frete.numero !== "" &&
      frete.cep.length === 9 &&
      valorFrete.id !== null
  }
})

export const validEntregaFieldState = selector({
  key: 'validEntregaFieldState',
  get: ({get}) => {
    const frete = get(freteState)
    const valorFrete = get(valorFreteState)

    return {
      endereco: frete.endereco !== "",
      bairro: frete.bairro !== "",
      estado: frete.estado !== "",
      cidade: frete.cidade !== "",
      numero: frete.numero !== "", 
      valor: valorFrete.id !== null
    }
  }
})
