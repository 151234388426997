import { Link } from 'react-router-dom'
import styled from 'styled-components'
import logoNamaste from '../assets/namasteLogo.png'


const FooterWrapper = styled.div`
  padding: 12px;
  color: white;
  max-width: 390px;
  margin: auto;
`

const TextBox = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`

const TextItalic = styled.div`
  font-style: italic;
`

const Paragraph = styled.div`
  padding: 8px;
  margin: 0;
  text-align: center;
  font-size: 14px;
`

const Namaste = styled.img`
  height: 80px;
`

const StyledLink = styled(Link)`
  margin-left: 8px;
  color: white;
  text-decoration: underline;
`

export default function Footer() {
  return (
    <FooterWrapper>
      <TextBox>
        <Namaste src={logoNamaste} alt='Namastê' title='Namastê' />
        <TextItalic>Te desafiando a algo novo!</TextItalic>
      </TextBox>
      <Paragraph>
        <span>Copyrights © 2022</span>
        <StyledLink to={'/termos-de-uso'}>Termos de uso</StyledLink> 
        <StyledLink to={'/privacidade'}>Política de privacidade</StyledLink>
      </Paragraph>
    </FooterWrapper>
  )
}
