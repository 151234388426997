import React from 'react'
import { Helmet } from "react-helmet-async"

const MetaInfo = ({metaInfos, product}) => {
  if(!metaInfos) {
    return null
  }
  
  return (
    <Helmet>
      <title>{metaInfos.title}</title>
      <meta name="title" content={metaInfos.title}/>
      <meta name="description" content={metaInfos.description}/>
      <link rel="canonical" href={metaInfos?.canonical} />
      <meta property="og:title" content={metaInfos.title} />
      <meta property="og:description" content={metaInfos.description}/>
      <meta property="og:url" content={metaInfos.url} />
      <meta property="og:image" content={metaInfos.image} />
      <meta property="og:image:alt" content={metaInfos.title} />
      <meta property="og:image:width" content="800" />
      <meta property="og:image:height" content="800" />

      <meta name="twitter:card" content="summary" />
      <meta name="twitter:title" content={metaInfos.title} />
      <meta name="twitter:description" content={metaInfos.description} />
      <meta name="twitter:image" content={metaInfos.image} />

      {product && <script type="application/ld+json">
        {`{
          "@context": "https://schema.org/",
          "@type": "Product",
          "name": "${metaInfos.title}",
          "image": "${metaInfos.image}",
          "description": "${metaInfos.description}",
          "brand": {
            "@type": "Brand",
            "name": "Agenda Namastê"
          },
          "offers": {
            "@type": "Offer",
            "url": "${metaInfos?.canonical}",
            "priceCurrency": "BRL",
            "price": "${product?.preco}",
            "availability": "https://schema.org/InStock",
            "itemCondition": "https://schema.org/NewCondition"
          }
        }`}
      </script>}
    </Helmet>
  )
}

export default MetaInfo
