import { Helmet } from "react-helmet-async"
import styled, { css } from "styled-components"
import { BrowserView, MobileView } from "react-device-detect"
import Menu from "../components/Menu"
import CartMenu from '../components/CartMenu'
import { CenterMenu, FooterWrapper, MenuWrapper, MenuWrapperDesktop } from '../components/HomeStyles'
import { PageWrapper } from '../components/DefaultStyles'
import LogoMenu from "../components/LogoMenu"
import MenuDesktop from "../components/MenuDesktop"

import sobreAgenda from '../assets/images/sobreagenda.jpeg'
import Footer from "../components/Footer"

const BoxContent = styled.div`
  padding: 12px;
  font-weight: 300;
  color: #333;
  font-size: 16px;
  max-width: 1200px;
  margin: auto;
`

const Content = styled.p`
  padding: 8px 24px;

  ${props => props.center && css({
    textAlign: "center"
  })}
`

const Title = styled.h2`
  font-size: 46px;
  padding: 24px 24px 0 24px;
  margin-bottom: 0;
  font-family: 'Bebas Neue';
  font-weight: 300;
  color: rgba(12,145,156,1);
`

const AlignImage = styled.div`
  text-align: center;

  img {
    max-height: 400px;
  }
`

export default function SobreAgenda() {
  return (
    <PageWrapper background={'white'}>
      <Helmet>
        <title>Sobre a Agenda</title>
      </Helmet>

      <MobileView>
        <MenuWrapper color={"rgba(12,145,156, 1)"}>
          <Menu background={'rgba(12,145,156, 1)'} />
          <LogoMenu />
          <CartMenu />
        </MenuWrapper>
      </MobileView>

      <BrowserView>
        <CenterMenu>
          <MenuWrapperDesktop color={"rgba(12,145,156, 1)"}>
            <LogoMenu />
            <MenuDesktop />
            <CartMenu />
          </MenuWrapperDesktop>
        </CenterMenu>
      </BrowserView>

      <BoxContent>
        <Title>Sobre a Agenda</Title>

        <AlignImage>
          <a rel="noreferrer" target="_blank" href="https://www.namaste.com.br" alt="Namastê Poa">
            <img src={sobreAgenda} alt='Faixada namastê porto alegre' title='Namastê Poa' />
          </a>
        </AlignImage>

        <Content>
          Uma agenda nada ordinária, que vai muito além de dias, meses e compromissos. Em cada página, criamos uma nova arte, provocação e alguns textos que vão mexer contigo. Te tirar da vida de compromissos de segunda a sexta, te tirar do ser humano robô! Fazer você sentir o seu coração e ir atrás desse sentimento ao longo do ano. É isso que queremos!
        </Content>

        <Content>
          Por isso também, no início de cada mês homenageamos uma pessoa que seguiu seu coração, sua verdade e mudou a realidade do seu redor! São os buscadores da verdade, que com certeza vão inspirar você a ser um também. E cada início de mês também conta com o feedback sexual, para você anotar suas transas, não transas, momento de tesão, fogo e amor. Tem coragem de descobrir como está sua vida sexual?
        </Content>

        <Content>
          A agenda é feita por uma equipe de artistas e terapeutas que vivem junto com uma comunidade inteira o conteúdo encontrado na agenda.Por isso tudo o que você lê aqui tem uma verdade, uma vivência e nosso coração.
        </Content>

        <Content>
          Esse ano, a agenda é inspirada pelo mestre Belchior. Belchior toca fundo nas entranhas, de forma forte e muitas vezes não agradável. Mas quando escutamos suas músicas sentimos sua verdade e a sensação de cumplicidade de que não estamos sozinhos. Ele nos lembra que o PASSADO É UMA ROUPA QUE NÃO NOS SERVE MAIS. Mas que UMA NOVA MUDANÇA EM BREVE VAI ACONTECER e PRECISAMOS TODOS REJUVENSCER.
        </Content>

        <Content>
          Amamos seu coração vibrante e sua simplicidade ao dizer que O AMOR É UMA COISA BOA! Você concorda?
        </Content>

        <Content>
          Então deixa a gente tocar teu coração com os nossos! <br />
          Grande abraço, <br />
          Equipe Agenda Namastê e Comunidade.
        </Content>
      </BoxContent>
      <FooterWrapper>
        <Footer />
      </FooterWrapper>
    </PageWrapper>
  )
}
