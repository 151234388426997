import styled from "styled-components"
import { useRecoilState } from "recoil"
import { useCart } from 'react-use-cart'
import { brlCurrency } from "../../helpers/agendas"
import { clienteState } from "../../atoms/clienteAtom"
import { freteState, valorFreteState } from "../../atoms/freteAtom"
import { cartaoDeCreditoState } from "../../atoms/cartaoDeCreditoAtom"
import { tipoCobrancaState } from "../../atoms/tipoCobrancaAtom"
import { PAGAMENTO_BOLETO, PAGAMENTO_CARTAO } from "../../helpers/constants"
import { InputInline } from './CarrinhoStyles'
import { aceitoTermosState } from "../../atoms/aceitoTermosAtom"
import { useState } from "react"
import ModalTermos from "../ModalTermos"

const BoxInfo = styled.div`
  border: solid 1px gray;
  padding: 12px;
  margin-bottom: 12px;
`

const BoxTitle = styled.h3`
  margin: 0;
  padding-bottom: 12px;
  color: #b7514b;
`

const InfoText = styled.p`
  margin: 0;
  padding: 0 0 4px 0;
`

const InfoTextFlex = styled.p`
  display: flex;
  align-items: center;
`

const TermosHighLight = styled.span`
  color: #b7514b;
  cursor: pointer;
`

const LabelTermos = styled.span`
  margin-left: 8px;
`

export default function Finalizar({stepId, activeStep,}) {
  const hideEdit = stepId > activeStep
  const { items, cartTotal } = useCart()
  const [valorFrete] = useRecoilState(valorFreteState)
  const [frete] = useRecoilState(freteState)
  const [cliente] = useRecoilState(clienteState)
  const [card] = useRecoilState(cartaoDeCreditoState)
  const [tipoCobranca] = useRecoilState(tipoCobrancaState)
  const [aceitoTermos, setAceitoTermos] = useRecoilState(aceitoTermosState)
  const [modalIsOpen, setIsOpen] = useState(false)

  if(hideEdit) {
    return null
  }

  const toggleTermos = () => {
    setAceitoTermos(!aceitoTermos)
  }
  
  return(
    <div>
      <ModalTermos isOpen={modalIsOpen} setIsOpen={setIsOpen}/>
      <h2>Confirme sua compra!</h2>

      <BoxInfo>
        <BoxTitle>Seus dados</BoxTitle>
        <InfoText>{cliente.nome} {cliente.sobrenome}</InfoText>
        <InfoText>{cliente.telefone}</InfoText>
        <InfoText>{cliente.email}</InfoText>
        <InfoText>{cliente.cpf}</InfoText>
      </BoxInfo>

      <BoxInfo>
        <BoxTitle>Entrega</BoxTitle>
        <InfoText>{frete.cidade} {frete.estado}</InfoText>
        <InfoText>{frete.endereco} - {frete.bairro}, {frete.numero} {frete.complemento}</InfoText>
      </BoxInfo>

      <BoxInfo>
        <BoxTitle>Informações de Envio</BoxTitle>
        <InfoText>Como qualquer compra online, o envio só será processado após confirmação do pagamento. A postagem ocorrerá em até dois dias úteis a partir dessa confirmação. A partir dai corre o prazo do frete escolhido pelo cliente. Para Clientes com frete grátis, a entrega será processada a partir do pagamento, podendo ser feita em até 7 dias depois do pagamento ser confirmado.</InfoText>
      </BoxInfo>

      <BoxInfo>
        <BoxTitle>Produtos</BoxTitle>
        {items.map(item => <InfoText key={item.id}>{item.quantity}x { item.nome } - { brlCurrency(item.itemTotal) }</InfoText>)}
        <InfoText>Frete: {brlCurrency(valorFrete.valor)}</InfoText>
        <InfoText>Total: {brlCurrency(cartTotal + valorFrete.valor)}</InfoText>
      </BoxInfo>

      { tipoCobranca === PAGAMENTO_CARTAO && <BoxInfo>
        <BoxTitle>Pagamento</BoxTitle>
        <InfoText>Cartão de crédito</InfoText>
        <InfoText>{card.parcelas.label}</InfoText>
      </BoxInfo> }

      { tipoCobranca === PAGAMENTO_BOLETO && <BoxInfo>
        <BoxTitle>Pagamento</BoxTitle>
        <InfoText>Boleto</InfoText>
      </BoxInfo> }

      <BoxInfo>
        <BoxTitle>Termos de uso</BoxTitle>
        <InfoTextFlex>
          <InputInline
            type="checkbox"
            id="termos-de-uso"
            checked={aceitoTermos}
            onClick={toggleTermos}
            onChange={() => {}}
          />
          <span>
            <LabelTermos htmlFor="termos-de-uso"> Eu li e concordo </LabelTermos> com os
            <TermosHighLight onClick={() => setIsOpen(true)}> termos de uso </TermosHighLight>
          </span>
        </InfoTextFlex>
      </BoxInfo>

    </div>
  )
}
