import { Fragment } from 'react'
import { PageSubheader } from '../../components/DefaultStyles'
import styled from "styled-components"
import { NavLink } from 'react-router-dom'

const NavLinkStyled = styled(NavLink)`
  text-align: center;
  display: inline-block;
  width: 100%;
  font-size: 20px;
`

export default function CarrinhoVazio() {
  return (
    <Fragment>
      <PageSubheader>Seu carrinho está vázio</PageSubheader>
      <NavLinkStyled to="/">Ir as Compras!</NavLinkStyled>
    </Fragment>
  )
}