import { Fragment, useEffect, useState } from "react";
import { BuyButtonInternal, DescriptionAgenda, Detalhes, IframeStyledDesktop, VideoCenter } from "../styled/agenda.styled";
import BuyButton from "./BuyButton";

export default function AgendaDescription({agenda, agendaCart}) {
  const [text, setText] = useState([])
  
  useEffect(() => {
    if(agendaCart.descricao && agendaCart.descricao !== '') {
      const splitedText = agendaCart.descricao.split('\r\n')
      if(splitedText) {
        setText(splitedText.filter(a => a !== ""))
      }
    }
  }, [agendaCart])
  
  return (
    <>
      <Detalhes color={agenda.tituloInterno}>
        Detalhes
      </Detalhes>

      {text.map((t, index) => (
        <Fragment key={`video-${index}`}>
          {index === 1 && (
            <VideoCenter>
              <IframeStyledDesktop src="https://www.youtube.com/embed/uwQu1nvVPQ8?autoplay=1&mute=1" title="Agenda Namastê" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen />
            </VideoCenter>
          )}
          
          <DescriptionAgenda key={index}>
            {t}
          </DescriptionAgenda>
        </Fragment>
      ))}

      {/* <DescriptionAgenda>
        Não é uma agenda qualquer! A Agenda Namastê leva um conteúdo de sexualidade, autoconhecimento, meditação e muita inspiração para 2022 não passar em branco!
      </DescriptionAgenda>
      <DescriptionAgenda>
        {agenda.textoInterno}
      </DescriptionAgenda> */}

      {/* <DescriptionAgenda>
        Criada coletivamente por artistas e amigos que moram em comunidade, se trabalham emocionalmente e acreditam na busca da sua verdade para uma vida mais natural de coração. Essa vivência é totalmente refletida em cada página da agenda, através de arte, inspirações, questionamentos e conteúdo reflexivo.
      </DescriptionAgenda>
      <DescriptionAgenda>
        280 páginas, 13 x 18cm, papel pólen 70g, Internamente, todas as Agendas Namastê possuem o mesmo conteúdo. Apenas a capa varia.
      </DescriptionAgenda> */}

      <BuyButtonInternal>
        <BuyButton
          color={agenda.quero}
          esgotado={agendaCart.esgotado}
          agenda={agendaCart}
          inside={false}
        />
      </BuyButtonInternal>
    </>
  )
}