import { Fragment } from 'react'
import CreditCardInput from 'react-credit-card-input'
import { useRecoilState, useRecoilValue } from 'recoil'
import { cartaoDeCreditoState, validCartaoDeCreditoState } from '../../atoms/cartaoDeCreditoAtom'
import { loadingParcelasState, parcelasQuery } from '../../atoms/parcelasAtom'
import { customStyleSelect, FieldSet, Input, Label, SelectStyled } from './CarrinhoStyles'

export function CartaoDeCredito() {
  const [card, setCard] = useRecoilState(cartaoDeCreditoState)
  const [validCard, setValidCard] = useRecoilState(validCartaoDeCreditoState)
  const [loadingParcelas] = useRecoilState(loadingParcelasState)
  const parcelas = useRecoilValue(parcelasQuery)

  function validateHolderName(holderName) {
    const especialCaracters = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/
    const number = /\d/
    setValidCard({
      ...validCard,
      holderName: holderName.length > 2 && !number.test(holderName) && !especialCaracters.test(holderName),
    })
  }

  function validateParcelas(parcela) {
    setValidCard({
      ...validCard,
      parcelas: parcela > 0 && parcela < 13,
    })
  }

  return (
    <Fragment>
      <FieldSet>
        <Label htmlFor="parcelas">Parcelas</Label>
        <SelectStyled
          isSearchable={false}
          value={card.parcelas}
          styles={customStyleSelect}
          $error={!validCard.parcelas}
          options={parcelas}
          placeholder="parcelas"
          isLoading={loadingParcelas}
          onChange={e => {
            setCard({...card, parcelas: e})
            validateParcelas(e.value)
          }}
        />
      </FieldSet>
      <FieldSet>
        <Label htmlFor="titular">Nome do Titular (como escrito no cartão)</Label>
        <Input
          id="titular"
          error={!validCard.holderName}
          value={card.holderName}
          onChange={e => {
            setCard({...card, holderName: e.target.value})
            validateHolderName(e.target.value)
          }}
        />
      </FieldSet>

      <CreditCardInput
        containerStyle={{width: '100%'}}
        inputStyle={{width: '115%'}}
        fieldStyle={{justifyContent: "space-evenly", border: "solid 1px #999"}}

        cardNumberInputProps={{
          value: card.number,
          onChange: e => {
            setCard({...card, number: e.target.value})
            setValidCard({...validCard, number: true})
          },
          onError: e => setValidCard({...validCard, number: false})
        }}
        cardExpiryInputProps={{
          value: card.expiry,
          onChange: e => {
            setCard({...card, expiry: e.target.value})
            setValidCard({...validCard, expiry: true})
          },
          onError: e => setValidCard({...validCard, expiry: false})
        }}
        cardCVCInputProps={{
          value: card.cvc,
          onChange: e => {
            setCard({...card, cvc: e.target.value})
            setValidCard({...validCard, cvc: true})
          },
          onError: e => setValidCard({...validCard, cvc: false})
        }}

        customTextLabels={{
          invalidCardNumber: 'Numero do cartão de crédito invalido',
          expiryError: {
            invalidExpiryDate: 'Data de expiração inválida',
            monthOutOfRange: 'Mês deve estar entre 1 e 12',
            yearOutOfRange: 'Ano de expiração não pode estar no passado',
            dateOutOfRange: 'A data de expiração não pode estar no passado'
          },
          invalidCvc: 'Código de segurança inválido',
          invalidZipCode: 'Cep inválido',
          cardNumberPlaceholder: 'Número do cartão',
          expiryPlaceholder: 'MM/AA',
          cvcPlaceholder: 'CVV',
          zipPlaceholder: 'CEP'
        }}
      />
    </Fragment>
  )
}