import React, { useEffect } from "react"
import {
  BrowserRouter as Router,
  Switch,
  Route
} from "react-router-dom"
import { useRecoilState } from "recoil"

import Home from "./Home"
import Agenda from "./Agenda"
import Carrinho from "./Carrinho"
import MeuPedido from "./MeuPedido"
import PedidoFinalizado from "./PedidoFinalizado"
import Contatos from "./Contatos"
import Namaste from "./Namaste"
import { getAgendas } from '../helpers/api'
import { agendasQuery, fetchedAgendasState } from "../atoms/agendasAtom"
import SobreAgenda from "./SobreAgenda"
import TermosDeUso from "./TermosDeUso"
import Privacidade from "./Privacidade"
import { useCart } from "react-use-cart"
import Livro from "./Livro"

export default function RoutesPage() {
  const [fetched, setFetched] = useRecoilState(fetchedAgendasState)
  const [, setAgendasQuery] = useRecoilState(agendasQuery)
  const { emptyCart } = useCart()

  const VERSION = process.env.REACT_APP_VERSION

  useEffect(() => {
    if(!fetched) {
      setFetched(true)
      getAgendas().then(res => {
        if (res.status === "error") {
          window.onerror(`Erro ao carregar agendas`, `routes`)
          throw new Error("Error when fetch");
        }
        if(res.agendaVersion != VERSION) {
          emptyCart()
          window.location.reload()
        }

        setAgendasQuery(res.data)
      })
      .catch(() => {
        setFetched(false)
      })
    }
  }, [])

  return (
    <Router>
      <Switch>
        <Route path="/detalhes/:slug">
          <Agenda />
        </Route>
        <Route path="/carrinho">
          <Carrinho />
        </Route>
        <Route path="/meus-pedidos">
          <MeuPedido />
        </Route>
        <Route path="/pedido-finalizado">
          <PedidoFinalizado />
        </Route>
        <Route path="/contatos">
          <Contatos />
        </Route>
        <Route path="/namaste">
          <Namaste />
        </Route>
        <Route path="/sobre-a-agenda">
          <SobreAgenda />
        </Route>
        <Route path="/termos-de-uso">
          <TermosDeUso />
        </Route>
        <Route path="/privacidade">
          <Privacidade />
        </Route>
        <Route path="/editora-namaste/por-que-voce-mente-e-eu-acredito">
          <Livro />
        </Route>
        <Route path="/">
          <Home />
        </Route>
      </Switch>
    </Router>
  )
}
