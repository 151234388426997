import { Link } from "react-router-dom"
import styled from "styled-components"
import logoEditora from '../assets/editora_logo.png'

const Titulo = styled.h2`
  font-family: "Bebas Neue";
  color: black;
  font-size: 30px;
  line-height: 0;
  padding: 0;
  margin: 0;
  font-weight: 300;
`

export default function LogoMenuLivro({height}) {
  return (
    <Link to={`/`}>
      <Titulo><img height={height} src={logoEditora} alt="Editora Namaste" /></Titulo>
    </Link>
  )
}