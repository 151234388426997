import styled, { keyframes } from 'styled-components'
import { stack as MenuComponent } from 'react-burger-menu'
import { VscMenu } from 'react-icons/vsc'
import { BiBookOpen, BiBookAlt, BiCircle } from 'react-icons/bi'
import { NavLink } from 'react-router-dom'
import { useParams } from 'react-router'
import { useState } from 'react'
import useInitialFetch from '../hooks/useInitialFetch'
import { useEffect } from 'react'
import { agendasQuery } from '../atoms/agendasAtom'
import { useRecoilState } from 'recoil'

import {
  Accordion,
  AccordionItem,
  AccordionItemHeading,
  AccordionItemButton,
  AccordionItemPanel,
} from 'react-accessible-accordion';
import { useCart } from 'react-use-cart'

// import 'react-accessible-accordion/dist/fancy-example.css';

const styles = {
  bmBurgerButton: {
    width: '36px',
  },
  bmBurgerBars: {
    background: 'white'
  },
  bmBurgerBarsHover: {
    background: '#CCC'
  },
  bmCrossButton: {
    height: '24px',
    width: '24px'
  },
  bmCross: {
    background: '#bdc3c7'
  },
  bmMenuWrap: {
    position: 'fixed',
    height: '100%',
    top: '0',
    marginLeft: '-12px',
  },
  bmMenu: {
    background: '#373a47',
    padding: '0.8em 0.5em 0',
    fontSize: '40px'
  },
  bmMorphShape: {
    fill: '#373a47'
  },
  bmItemList: {
    color: 'white',
  },
  bmItem: {
    display: 'block',
    color: 'white'
  },
  bmOverlay: {
    background: 'rgba(0, 0, 0, 0.3)'
  }
}

const MenuItem = styled.div`
  display: block;
  color: white;
  font-size: 24px;
`

const MenuItemSmall= styled.div`
  color: white;
  font-size: 18px;
  display: flex;
  align-items: center;
  padding: 4px;
`

const MenuWrapper = styled.div`
  position: relative;
  font-family: 'Bebas Neue';
`

const BiBookOpenStyled = styled(BiBookOpen)`
  margin-right: 12px;
`

const BiBookAltStyled = styled(BiBookAlt)`
  margin-right: 12px;
`

const BiCircleStyled = styled(BiCircle)`
  margin-right: 12px;
`

const AccordionItemWrapper = styled.div`
  display: flex;
  align-items: center;
`

const AccordionItemPanelCaderno = styled(AccordionItemPanel)`
  display: flex;
  flex-wrap: wrap;
`

const fadeIn = keyframes`
  from {opacity: 0;}
  to {opacity: 1;}
`

const AccordionItemPanelStyled = styled(AccordionItemPanel)`
  animation: ${fadeIn} 300ms linear;
`

const CadernoWrapperLink = styled.div`
  width: 50%;
`

const MenuDivider = styled.hr`
  margin: 8px 0;
`

const MenuCart = styled.div`
  position: relative;
  display: inline-block;
`

const BadgeCartMenu = styled.div`
  position: absolute;
  text-align: center;
  border-radius: 25px;
  color: white;
  min-width: 22px;
  height: 22px;
  padding: 0 8px;
  font-size: 16px;
  right: -14px;
  top: 4px;
  background-color: seagreen;
`

export default function Menu({background}) {
  styles.bmMenu.background = background
  const [isOpen, setIsOpen] = useState(false)
  const { loadVendedor } = useInitialFetch()
  const { id } = useParams();
  const [agendas] = useRecoilState(agendasQuery)
  const { totalItems } = useCart();

  useEffect(() => {
    loadVendedor()
  }, [])

  useEffect(() => {
    window.scrollTo(0,0)
  }, [id])

  return (
    <MenuWrapper>
      <MenuComponent
        left
        width={ '100%' }
        styles={ styles }
        customBurgerIcon={<VscMenu color={'white'} />}
        isOpen={isOpen}
        onStateChange={(state) => {
          setIsOpen(state.isOpen)
        }}
      >
        <NavLink to={`/`} onClick={() => setIsOpen(false)}>
          Início
        </NavLink>

        <NavLink to={`/carrinho`}onClick={() => setIsOpen(false)}>
          <MenuCart>
            Carrinho <BadgeCartMenu>{totalItems}</BadgeCartMenu>
          </MenuCart>
        </NavLink>

        <Accordion allowZeroExpanded>
          <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionItemWrapper>
                    <BiBookOpenStyled />
                    Agendas 2023
                  </AccordionItemWrapper>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanelStyled>
              {agendas.map(agenda => {
                if(agenda.isCaderno) {
                  return null
                }
                return (
                  <NavLink to={`/detalhes/${agenda.cartInfo[0].slug}`} onClick={() => setIsOpen(false)} key={agenda.cartInfo[0].slug}>
                    <MenuItemSmall>
                      <BiCircleStyled />
                      {agenda.cartInfo[0].nomeHome}
                    </MenuItemSmall>
                  </NavLink>    
                )
              })}
            </AccordionItemPanelStyled>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionItemWrapper>
                    <BiBookAltStyled />
                    Cadernos A5
                  </AccordionItemWrapper>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanelStyled>
              <AccordionItemPanelCaderno>
                {agendas.map(agenda => {
                  if(!agenda.isCaderno || agenda.nome === 'Cadernos A6') {
                    return null
                  }

                  return (
                    agenda.cartInfo.map((caderno, index) => {
                      return (
                        <CadernoWrapperLink key={`${caderno.slug}-${index}`}>
                          <NavLink to={`/detalhes/${caderno.slug}`} onClick={() => setIsOpen(false)}>
                            <MenuItemSmall>
                              <BiCircleStyled />
                              {caderno.nomeHome}
                            </MenuItemSmall>
                          </NavLink>
                        </CadernoWrapperLink>
                      )
                    })
                  )
                })}
              </AccordionItemPanelCaderno>
            </AccordionItemPanelStyled>
          </AccordionItem>
          <AccordionItem>
            <AccordionItemHeading>
                <AccordionItemButton>
                  <AccordionItemWrapper>
                    <BiBookAltStyled />
                    Cadernos A6
                  </AccordionItemWrapper>
                </AccordionItemButton>
            </AccordionItemHeading>
            <AccordionItemPanelStyled>
              <AccordionItemPanelCaderno>
                {agendas.map(agenda => {
                  if(!agenda.isCaderno || agenda.nome === 'Cadernos A5') {
                    return null
                  }

                  return (
                    agenda.cartInfo.map((caderno, index) => {
                      return (
                        <CadernoWrapperLink key={`${caderno.slug}-${index}`}>
                          <NavLink to={`/detalhes/${caderno.slug}`} onClick={() => setIsOpen(false)}>
                            <MenuItemSmall>
                              <BiCircleStyled />
                              {caderno.nomeHome}
                            </MenuItemSmall>
                          </NavLink>
                        </CadernoWrapperLink>
                      )
                    })
                  )
                })}
              </AccordionItemPanelCaderno>
            </AccordionItemPanelStyled>
          </AccordionItem>
        </Accordion>

        <MenuDivider />

        <NavLink to={`/contatos`}onClick={() => setIsOpen(false)}>
          <MenuItem>Contatos</MenuItem>
        </NavLink>

        <NavLink to={`/namaste`}onClick={() => setIsOpen(false)}>
          <MenuItem>Sobre o Namastê</MenuItem>
        </NavLink>

        <NavLink to={`/sobre-a-agenda`}onClick={() => setIsOpen(false)}>
          <MenuItem>Sobre a Agenda</MenuItem>
        </NavLink>
      </MenuComponent>
    </MenuWrapper>
  )
}