import styled, { css } from "styled-components"
import Select from 'react-select'
import MaskedInput from 'react-text-mask'

export const FieldSet = styled.div`
  display: flex;
  align-items: center;
  font-size: 18px;
  flex-wrap: wrap;
  margin-bottom: 12px;
`

export const FieldSetInline = styled(FieldSet)`
  flex-wrap: no-wrap;
  align-items: center;
`

export const Label = styled.label`
  margin-right: 12px;
  font-size: 16px;
  display: block;
  width: 100%;
`

export const LabelInline = styled(Label)`
  width: auto;
`

export const LabelEnd = styled(Label)`
  width: 18%
  margin-bottom: 12px;
`

export const Input = styled.input`
  padding: 8px;
  font-size: 18px;
  width: 100%;

  ${props => props.error && css({
    border: "solid 1px gray",
    borderRadius: "3px",
    borderBottom: "solid 2px #E22",
    color: "#E22",
    padding: "8px 8px 8px 9px",
  })}
`

export const InputInline = styled(Input)`
  width: 25px;
  height: 25px;
`

export const SelectStyled = styled(Select)`
  width: 100%;

  ${props => props.$error && css({
    borderRadius: "3px",
    borderBottom: "solid 1px #E22",
  })}
`

export const customStyleSelect = {
  control: (provided) => ({
    ...provided,
    borderRadius: '2px',
    padding: '2px',
    borderColor: 'gray'
  })
}

export const CartaoWrapper = styled.div`
  width: 100%;
  padding-top: 12px;
`

export const InputMask = styled(MaskedInput)`
  padding: 8px;
  font-size: 18px;
  width: 100%;

  ${props => props.$error && css({
    border: "solid 1px gray",
    borderRadius: "3px",
    borderBottom: "solid 2px #E22",
    color: "#E22",
    padding: "8px 8px 8px 9px",
  })}
`

export const OpcaoCheckbox = styled.div`
padding: 20px;
border-radius: 5px;
margin-bottom: 8px;
font-size: 18px;
border: 1px solid #ccc;
cursor: pointer;
flex-wrap: wrap;
border-right: 5px solid #ccc;
display: flex;
justify-content: space-between;
align-items: center;
content: "";

${props => css({
    borderRight: `5px solid ${props.active ? '#b7514b' : '#ccc'}`
  })
}
`

export const CheckboxWrapper = styled.div`
  height: 22px;
`

export const PagamentoCheck = styled.div`
  display: flex;
  align-items: center;
`

export const LabelPagamento = styled.div`
  margin-right: 24px;
`
